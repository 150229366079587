import React, { useState } from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useDispatch } from 'react-redux';
import { sendOtp, signUp } from './slice/SignUpSlice';
import { useTranslation } from 'react-i18next';




function SignupPage() {

    const [t, i18n] = useTranslation("global");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [isActive, setIsActive] = useState(false);
    const [condition, setCondition] = useState(1)
     const [btnName, setBtnName] = useState(t("signupPage.sendOtp"));
    const [signResp, setSignResp] = useState("");
    const [otpResp, setOtpResp] = useState("");
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleUserNumber = (event) => {
        setUsername(event.target.value);
    }

    const handleUserPassword = (event) => {
        setPassword(event.target.value);
    }

    const signupUser = () => {
        setIsActive(true);
        if (username !== null) {
            if (btnName === t("signupPage.sendOtp")) {
                dispatch(sendOtp({ username: username }))
                    .then((resp) => {
                        console.log(resp.payload.data)
                        if (resp.payload.data.resMessage === ("OTP_SEND")) {
                            setOtpResp(resp.payload.data.resMessage)
                            alert(resp.payload.data.resMessage);
                            setBtnName( t("signupPage.signup"))
                            setCondition(2);
                        }
                        else {
                            alert(resp.payload.data.resMessage);
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        alert(error);
                    });
            }
            else if (btnName ===  t("signupPage.signup")) {
                dispatch(signUp({ username: username, password: password }))
                    .then((resp) => {
                        console.log(resp.payload.data)
                        if (resp.payload.data.resMessage === ("SIGNUP_SUCCESS")) {
                            setSignResp(resp.payload.data.resMessage)
                            alert(resp.payload.data.resMessage);
                            // console.log(resp.payload.data);
                            navigate("/login")
                        }
                        else {
                            alert(resp.payload.data.resMessage);
                        }

                    })
                    .catch((error) => {
                        console.error(error);
                        alert(error);
                    });
            }
        }
    }


    return (
        <div className='container '>
            <div className='d-flex flex-column justify-content-center text-white' style={{ height: '100vh' }}>
                <div className='d-flex flex-column justify-content-center align-items-center'>
                    <div className='d-flex justify-content-center flex-column align-items-center'>
                        <AccountCircleIcon />
                        <h5>
                            <p>{t("signupPage.signUpAccount")}</p>
                        </h5>
                    </div>
                    <div className='d-flex justify-content-center flex-column'>
                        <div>
                            <label>{t("loginPage.mobileNum")} : </label>
                            <input type="number" className='rounded border border-0 p-2 w-100' placeholder={t("loginPage.enterMb")}  value={username} onChange={handleUserNumber}  onKeyDown={ (e) => { if (e.key === "Enter")  signupUser() }} />
                        </div>
                        {
                            condition === 1 ?
                                ""
                                :
                                condition === 2 ?
                                    <div >
                                        <label>{t("signupPage.otp")} : </label>
                                        <input type="password" className='rounded border border-0 p-2 w-100'  value={password} onChange={handleUserPassword} onKeyDown={                                          (e) => { if (e.key === "Enter")
                                           signupUser()
                                         }} />
                                    </div>
                                    :
                                    <div>
                                        <label style={{ marginTop: "1em", color: "white" }}> {t("signupPage.setPassword")} : </label>
                                        <input type="password" className='rounded border border-0 p-2 w-100'  value={password} onChange={handleUserPassword}  onKeyDown={                                          (e) => { if (e.key === "Enter")
                                           signupUser()
                                         }} />
                                    </div>
                        }
                    </div>
                    <Button id="btnOtp" className='text-white border border-1 my-4' onClick={signupUser}>
                        {btnName}
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default SignupPage;
