import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';

const initialState = {
    data: {},
    isLoading: true,
    error: false
}

const subType = sessionStorage.getItem("SubType");
let sub = subType?.replace(/"/g, '');

export const rbtofCat = createAsyncThunk("rbtofCat", async ({ username: username, category: category }) => {
    const subType = sessionStorage.getItem("SubType");
    let sub = subType?.replace(/"/g, '');
    const response = axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/Content/ToneListOfCategory?msisdn=${username}&service=${process.env.REACT_APP_SERVICE}&interface=${process.env.REACT_APP_INTERFACES}&langId=${process.env.REACT_APP_LANGID}&catId=${category}&pageNo=-1&countryCode=${process.env.REACT_APP_COUNTRY_CODE_SUDATEL}&subType=${sub}&planId=1&pageSize=`,
        // headers: {
        //     "Authorization": `Bearer ${data.token}`
        // },
    })
    return response;
})

// http://192.168.1.112:8898/Purchase/AddRBT?msisdn=" + username + "&langId=1&interface=W&service=CRBT&countryCode=220&subType=P&rbtCode=" + rbtCodes + "&planId=1
export const addRBTAlbum = createAsyncThunk("addRBTAlbum", async ({ username: username, rbtCode: rbtCode }) => {
    const subType = sessionStorage.getItem("SubType");
    let sub = subType?.replace(/"/g, '');
    const response = axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/Purchase/AddRBT?msisdn=${username}&service=${process.env.REACT_APP_SERVICE}&interface=${process.env.REACT_APP_INTERFACES}&langId=${process.env.REACT_APP_LANGID}&planId=1&subType=${sub}&rbtCode=${rbtCode}`,
        // headers: {
        //     "Authorization": `Bearer ${data.token}`
        // },
    })
    // console.log(response)
    return response;
})

// http://192.168.1.112:8898/Purchase/GiftRBT?msisdn=2203010007&service=CRBT&interface=W&langId=1&rbtCode=90752&planId=1&subType=P&fmsisdn=2203010002

export const giftRbt = createAsyncThunk("giftRbt", async ({ username: username, rbtCode: rbtCode, fmsisdn: fmsisdn }) => {
    const subType = sessionStorage.getItem("SubType");
    let sub = subType?.replace(/"/g, '');
    const response = axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/Purchase/GiftRBT?msisdn=${username}&service=${process.env.REACT_APP_SERVICE}&interface=${process.env.REACT_APP_INTERFACES}&langId=${process.env.REACT_APP_LANGID}&rbtCode=${rbtCode}&planId=1&subType=${sub}&fmsisdn=${fmsisdn}`,
        // headers: {
        //     "Authorization": `Bearer ${data.token}`
        // },
    })
    // console.log(response)
    return response;
})


// playRBT = http://localhost:8898/Content/MusicPlayer?msisdn=2203010007&service=CRBT&interface=W&langId=1&playPath=MjY1dDMvMWwzZTBtMGUybjR1Mi8wZTBkMHMwdTBwMGUwczAvMHIwbzBjMHQwbjBuMGUwLzB0MHUwYjBrMGwwdTBfMGwwcDBhMG8wLzBkMDIwMzAvMDMwYjBSME0wdDBzMHUwYzBpMGkwRjBlMGwwLzBzMDgwMjAzMDgwLjAyMGEwdzAwMHYw

export const playMusic = createAsyncThunk("playMusic", async ({ username: username, playPath: playPath }) => {
    const response = axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/Content/MusicPlayer?msisdn=${username}&service=${process.env.REACT_APP_SERVICE}&interface=${process.env.REACT_APP_INTERFACES}&langId=${process.env.REACT_APP_LANGID}&playPath=${playPath}`,
    })
    // console.log(response)
    return response;
})



// http://192.168.18.112:8890/CrbtAPI/Album/ADDRBTtoALBUM?msisdn=2203010007&service=CRBT&interface=W&langId=1&planId=1&subType=P&albumName=Tested&rbtCode=31969
export const addRBTIntoAlbum = createAsyncThunk("addRBTIntoAlbum", async ({ username: username, albumName: albumName, rbtCode: rbtCode }) => {
    const subType = sessionStorage.getItem("SubType");
    let sub = subType?.replace(/"/g, '');
    const response = axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/Album/ADDRBTtoALBUM?msisdn=${username}&service=${process.env.REACT_APP_SERVICE}&interface=${process.env.REACT_APP_INTERFACES}&langId=${process.env.REACT_APP_LANGID}&planId=1&subType=${sub}&albumName=${albumName}&rbtCode=${rbtCode}`,
    })
    // console.log(response)
    return response;
})


export const RbtTuneSlice = createSlice({
    name: 'rbtTunes',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(rbtofCat.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.error = false
            state.isLoading = false
        })
        builder.addCase(rbtofCat.pending, (state) => {
            state.error = false
            state.isLoading = true
        })
        builder.addCase(rbtofCat.rejected, (state) => {
            state.error = true
            state.isLoading = false
        })

        builder.addCase(addRBTAlbum.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.error = false
            state.isLoading = false
        })
        builder.addCase(addRBTAlbum.pending, (state) => {
            state.error = false
            state.isLoading = true
        })
        builder.addCase(addRBTAlbum.rejected, (state) => {
            state.error = true
            state.isLoading = false
        })

        builder.addCase(giftRbt.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.error = false
            state.isLoading = false
        })
        builder.addCase(giftRbt.pending, (state) => {
            state.error = false
            state.isLoading = true
        })
        builder.addCase(giftRbt.rejected, (state) => {
            state.error = true
            state.isLoading = false
        })
    }
})





export default RbtTuneSlice.reducer