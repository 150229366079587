import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';

const initialState = {
    data: {},
    isLoading: true,
    error: false
}


// http://192.168.1.112:8898/Authenticate/SENDOTP?msisdn=' + username + '&service=CRBT&interface=W&langId=1
export const sendOtp = createAsyncThunk("sendOtp", async ({ username: username }) => {
    const response = axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/Authenticate/SENDOTP?msisdn=${username}&service=${process.env.REACT_APP_SERVICE}&interface=${process.env.REACT_APP_INTERFACES}&langId=${process.env.REACT_APP_LANGID}`,
        // headers: {
        //     "Authorization": `Bearer ${data.token}`
        // },
    })
    // console.log(response)
    return response;
})

// http://192.168.1.112:8898/Authenticate/SIGNUP?msisdn=' + username + '&service=CRBT&interface=W&langId=1&password=' + password

export const signUp = createAsyncThunk("signUp", async ({ username: username, password: password }) => {
    const response = axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/Authenticate/SIGNUP?msisdn=${username}&service=${process.env.REACT_APP_SERVICE}&interface=${process.env.REACT_APP_INTERFACES}&langId=${process.env.REACT_APP_LANGID}&password=${password}`,
        // headers: {
        //     "Authorization": `Bearer ${data.token}`
        // },
    })
    // console.log(response)
    return response;
})





export const SignUpSlice = createSlice({
    name: 'signupSlice',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(sendOtp.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.error = false
            state.isLoading = false
        })
        builder.addCase(sendOtp.pending, (state) => {
            state.error = false
            state.isLoading = true
        })
        builder.addCase(sendOtp.rejected, (state) => {
            state.error = true
            state.isLoading = false
        })


        builder.addCase(signUp.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.error = false
            state.isLoading = false
        })
        builder.addCase(signUp.pending, (state) => {
            state.error = false
            state.isLoading = true
        })
        builder.addCase(signUp.rejected, (state) => {
            state.error = true
            state.isLoading = false
        })
    }
})

export default SignUpSlice.reducer