import React, { useState, useEffect } from 'react';
import PlayCircleOutlineSharpIcon from '@mui/icons-material/PlayCircleOutlineSharp';
import PauseCircleOutlineSharpIcon from '@mui/icons-material/PauseCircleOutlineSharp';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { myLibrary } from './slice/MyTonesLibrary';
import { Button, Checkbox, FormControlLabel, Tooltip } from '@mui/material';
import ReactLoading from "react-loading";
import { playMusic } from '../homepage/slice/RbtofCatSlice';
import { useTranslation } from 'react-i18next';
// import PlayArrowSharpIcon from '@mui/icons-material/PlayArrowSharp';
// import Favorite from '@material-ui/icons/Favorite';
// import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
// import BorderColorSharpIcon from '@mui/icons-material/BorderColorSharp';
// import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp';




let audio = null;
function MyTones() {


    const [t, i18n] = useTranslation("global");

    const [myTone, setMyTone] = useState([]);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [playIcon, setPlayIcon] = useState(<PlayCircleOutlineSharpIcon style={{ color: "white", width: '30px', height: '30px' }} />)
    const [respMsg, setRespMsg] = useState("");
    const [filePath, setFilePath] = useState("");
    const [playButton, setPlayButton] = useState(t("sideMenu.home.play"));
    let result = sessionStorage.getItem("Username");
    result = JSON.parse(result);
    const username = result
    const data = useSelector(state => state.myTonesLibrary)



    useEffect(() => {
        dispatch(myLibrary({ username: result }))
            .then((resp) => {
                // console.log(resp.payload.data)
                if (resp.payload.data.resMessage === "Tone list fetched successfully") {
                    setMyTone(resp.payload.data.crbtProfile.toneList)
                    setRespMsg(resp.payload.data.resMessage)
                }
                else {
                    setRespMsg(resp.payload.data.resMessage)
                }
            })
            .catch((error) => {
                // console.error(error);
                toast.error(error);
            });
    }, []);


    const playAudio = (rbt) => {
        // console.log(filePlayPath)
        audio = new Audio(rbt);
        audio.play();
    };


    const handlePlay = (playPath) => {
        // const decodedText = atob(playPath);
        // audio.crossOrigin = 'anonymous';

        if (playButton === t("sideMenu.home.pause")) {
            setPlayIcon(<PlayCircleOutlineSharpIcon style={{ color: "white", width: '30px', height: '30px' }} />)
            setPlayButton(t("sideMenu.home.play"));
        }
        if (playButton === t("sideMenu.home.play")) {
            // console.log("going to play")
            setPlayButton(t("sideMenu.home.pause"));
            setPlayIcon(<PauseCircleOutlineSharpIcon style={{ color: "white", width: '30px', height: '30px' }} />)
            dispatch(playMusic({ username: username, playPath: playPath }))
                .then((resp) => {
                    let filePlay = resp.payload.data.filePath;
                    filePlay = filePlay.replace("/home/crbtuser/voice/Crbt_Categories/", "/Crbt_Category/");
                    const rbt = require('../music' + filePlay);
                    setFilePath(rbt);
                    playAudio(rbt)
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error);
                });
        }
    }

    const handlePause = (playPath) => {
        audio.pause();
        console.log(playPath)
        if (playButton === t("sideMenu.home.pause")) {
            setPlayIcon(<PlayCircleOutlineSharpIcon style={{ color: "white", width: '30px', height: '30px' }} />)
            setPlayButton(t("sideMenu.home.play"));
        }
        if (playButton === t("sideMenu.home.play")) {
            setPlayButton(t("sideMenu.home.pause"));
            setPlayIcon(<PauseCircleOutlineSharpIcon style={{ color: "white", width: '30px', height: '30px' }} />)
        }
    }




if ((sessionStorage.getItem("Username") !== null) && (sessionStorage.getItem("Username") !== "")) {

    if (data.isLoading) {
        return (
            <div className='d-flex justify-content-center align-items-center    '>
                <ReactLoading type="bars" color="white"
                    height={100} width={50} />
            </div>
        )
    }
    else {
        return (
            <div className='container' style={{ backgroundColor: 'transparent' }}>
                <div className='text-white'>
                    <h3 style={{ textAlign: 'center' }}>
                        {t("sideMenu.yourLibrary.heading")}
                    </h3>

                    {
                        respMsg === "Tone list fetched successfully"
                            ?

                            <div style={{ marginBottom: '25px' }}>
                                {myTone.map((getmytone, i) => (
                                    <div className='d-flex align-items-center'>
                                        <div>
                                            <FormControlLabel title={playButton === t("sideMenu.home.play") ? t("sideMenu.home.play") : t("sideMenu.home.pause")} onChange={playButton === t("sideMenu.home.play") ? () => { handlePlay(getmytone.playPath, getmytone.rbtCode) } : () => { handlePause(getmytone.playPath, getmytone.rbtCode) }}
                                                control={
                                                    <Checkbox
                                                        icon={
                                                            <Tooltip title={t("sideMenu.home.play")} className='d-flex justify-content-center align-items-center text-white' arrow>
                                                                <PlayCircleOutlineSharpIcon style={{ color: "white", width: '30px', height: '30px' }} />
                                                            </Tooltip>
                                                        }
                                                        checkedIcon={
                                                            <Tooltip title={t("sideMenu.home.pause")} className='d-flex justify-content-center align-items-center text-white' arrow>
                                                                <PauseCircleOutlineSharpIcon style={{ color: "white", width: '30px', height: '30px' }} />
                                                            </Tooltip>
                                                        }
                                                        name="checkedH" />
                                                }
                                            />
                                        </div>
                                        <div className=' d-flex justify-content-between align-items-center my-4 rounded w-50' key={i} style={{ backgroundColor: 'rgba(0,0,0,0.2)', padding: '1em' }}>
                                            <div className='d-flex flex-column'>
                                                <div className='d-flex'>{(getmytone.rbtName)}</div>
                                                <div className='d-flex' style={{ color: '#e0e192', fontSize: '12px' }}>--  {(getmytone.artistName)} , {(getmytone.rbtCode)}</div>
                                                <div className='my-3 d-flex'>
                                                    <Link to={"/crbt/setrbt/" + getmytone.rbtCode} state={{ rbtCode: getmytone.rbtCode }}  >
                                                        <Button className='text-white border border-1 px-4' >
                                                        {t("sideMenu.yourLibrary.setRbt")}
                                                        </Button>
                                                    </Link>
                                                </div>
                                            </div>
                                            {/* <div className='d-flex'>
                                        Image
                                    </div> */}
                                        </div>
                                    </div>
                                ))
                                }
                            </div >
                            :
                            <div className='d-flex text-white justify-content-center my-5 '>
                                <h4 className='border border-1 p-2 rounded'>
                                    {respMsg}
                                </h4>
                            </div>
                    }
                </div >
            </div >
        );
    }
}
    else {
        navigate("/")
    }
}


export default MyTones;
