import React, { useState } from 'react';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import LibraryMusicOutlinedIcon from '@mui/icons-material/LibraryMusicOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import HomeIcon from '@mui/icons-material/Home';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';







function Sidemenu() {



    const [t, i18n] = useTranslation("global");
    const [home, setHome] = useState(t("sideMenu.home.title"));
    const [category, setCategory] = useState(t("sideMenu.category.title"))
    const [library, setLibrary] = useState(t("sideMenu.yourLibrary.title"))
    const [tone, setTone] = useState(t("sideMenu.toneSetting.title"))
    const [frndSetting, setFrndSetting] = useState(t("sideMenu.friendSetting.title"))
    const menuItem = [
        {
            path: "/crbt/home",
            name: home,
            icon: <HomeIcon />
        },
        {
            path: "/crbt/categoryList",
            name: category,
            icon: <LibraryBooksOutlinedIcon />
        },
        {
            path: "/crbt/library",
            name: library,
            icon: <LibraryMusicOutlinedIcon />
        },
        {
            path: "/crbt/toneSetting",
            name: tone,
            icon: <SettingsOutlinedIcon />
        },
        {
            path: "/crbt/freindSetting",
            name: frndSetting,
            icon: <Diversity1Icon />
        },
        // {
        //     path: "/crbt/groupSetting",
        //     name: "Group Setting",
        //     icon: <Diversity3Icon />
        // }
    ]

    return (
        <div style={{ marginTop: '6em' }} >
            <div >
                {
                    menuItem.map((route, index) => {
                        return (
                            <NavLink to={route.path} key={index} className="link" activeClassName='active'>
                                <div className='icon'>{route.icon} </div>
                                <div> {route.name} </div>
                            </NavLink>
                        );
                    })
                }
            </div>
        </div>


    );
}



export default Sidemenu;