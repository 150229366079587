import React, { useEffect } from 'react'
import { RouterProvider } from 'react-router-dom';
import { Flip, ToastContainer } from 'react-toastify';
import Router from './Router';
import './App.css';

export default function App() {
  // const loading = useSignal(false);


  return (
    <>
      <RouterProvider router={Router} />
      <ToastContainer theme='colored' autoClose='1500' transition={Flip} closeOnClick pauseOnHover />
    </>
  )

}
