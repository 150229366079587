import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';

const initialState = {
    data: {},
    isLoading: true,
    error: false
}



const subType = sessionStorage.getItem("SubType");
let sub = subType?.replace(/"/g, '');
// http://192.168.1.112:8898/Friend/AddFriend?msisdn=' + result + '&service=CRBT&interface=W&langId=1&fmsisdn=' + fmsisdn + '&friendName=' + nickName + '&subType=P

export const addFriend = createAsyncThunk("addFriend", async ({ username: username, fmsisdn: fmsisdn, nickName: nickName }) => {
    const subType = sessionStorage.getItem("SubType");
    let sub = subType?.replace(/"/g, '');
    const response = axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/Friend/AddFriend?msisdn=${username}&service=${process.env.REACT_APP_SERVICE}&interface=${process.env.REACT_APP_INTERFACES}&langId=${process.env.REACT_APP_LANGID}&fmsisdn=${fmsisdn}&friendName=${nickName}&subType=${sub}`,
        // headers: {
        //     "Authorization": `Bearer ${data.token}`
        // },
    })
    // console.log(response)
    return response;
})

// http://192.168.1.112:8898/Friend/ShowFriends?msisdn=" + result.username + "&service=CRBT&interface=W&langId=1

export const getFriendList = createAsyncThunk("getFriendList", async ({ username: username }) => {
    const response = axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/Friend/ShowFriends?msisdn=${username}&service=${process.env.REACT_APP_SERVICE}&interface=${process.env.REACT_APP_INTERFACES}&langId=${process.env.REACT_APP_LANGID}`,
        // headers: {
        //     "Authorization": `Bearer ${data.token}`
        // },
    })
    // console.log(response)
    return response;
})


// http://192.168.1.112:8898/Friend/RenameFriend?msisdn=" + result.username + "&service=CRBT&interface=W&langId=1&fmsisdn=" + fmsisdn + "&friendName=" + frndName + "&subType=P

export const modifyFriendName = createAsyncThunk("modifyFriendName", async ({ username: username, fmsisdn: fmsisdn, frndName: frndName }) => {
    const subType = sessionStorage.getItem("SubType");
    let sub = subType?.replace(/"/g, '');
    const response = axios({
        method: 'put',
        url: `${process.env.REACT_APP_API_URL}/Friend/RenameFriend?msisdn=${username}&service=${process.env.REACT_APP_SERVICE}&interface=${process.env.REACT_APP_INTERFACES}&langId=${process.env.REACT_APP_LANGID}&fmsisdn=${fmsisdn}&friendName=${frndName}&subType=${sub}`,
        // headers: {
        //     "Authorization": `Bearer ${data.token}`
        // },
    })
    console.log(response)
    return response;
})


// http://192.168.1.112:8898/Friend/DeleteFriend?msisdn=" + result.username + "&service=CRBT&interface=W&langId=1&fmsisdn=" + fmsisdn + "&subType=P

export const deleteFriend = createAsyncThunk("deleteFriend", async ({ username: username, fmsisdn: fmsisdn }) => {
    const subType = sessionStorage.getItem("SubType");
    let sub = subType?.replace(/"/g, '');
    const response = axios({
        method: 'delete',
        url: `${process.env.REACT_APP_API_URL}/Friend/DeleteFriend?msisdn=${username}&service=${process.env.REACT_APP_SERVICE}&interface=${process.env.REACT_APP_INTERFACES}&langId=${process.env.REACT_APP_LANGID}&fmsisdn=${fmsisdn}&subType=${sub}`,
        // headers: {
        //     "Authorization": `Bearer ${data.token}`
        // },
    })
    // console.log(response)
    return response;
})



export const FriendSlice = createSlice({
    name: 'friendSlice',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(addFriend.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.error = false
            state.isLoading = false
        })
        builder.addCase(addFriend.pending, (state) => {
            state.error = false
            state.isLoading = true
        })
        builder.addCase(addFriend.rejected, (state) => {
            state.error = true
            state.isLoading = false
        })

        builder.addCase(getFriendList.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.error = false
            state.isLoading = false
        })
        builder.addCase(getFriendList.pending, (state) => {
            state.error = false
            state.isLoading = true
        })
        builder.addCase(getFriendList.rejected, (state) => {
            state.error = true
            state.isLoading = false
        })

        builder.addCase(modifyFriendName.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.error = false
            state.isLoading = false
        })
        builder.addCase(modifyFriendName.pending, (state) => {
            state.error = false
            state.isLoading = true
        })
        builder.addCase(modifyFriendName.rejected, (state) => {
            state.error = true
            state.isLoading = false
        })
    }
})

export default FriendSlice.reducer