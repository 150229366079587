import React, { Component } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import HeaderComponent from './allPages/headerFooter/HeaderComponent';
import Sidemenu from './allPages/sidemenu/Sidemenu';


function Layout() {
    const location = useLocation();
    // console.log(location.state);
    let result = sessionStorage.getItem("Username");  //getting value of session
    result = JSON.parse(result);

    return (
        <div className='d-flex '>
            <div style={{ top: 0, left: 0, position: 'sticky', height: '100vh' }}>
                <Sidemenu />
            </div>
            <main className='container' >
                <div style={{ top: 0, right: 0, position: 'sticky' }}>
                    <HeaderComponent user={result} />
                </div>

                <Outlet />

            </main>

        </div >
    );
    // }
}

export default Layout;