import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import SendSharpIcon from '@mui/icons-material/SendSharp';
import BorderColorSharpIcon from '@mui/icons-material/BorderColorSharp';
import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp';
import { useDispatch } from 'react-redux';
import { deleteFriend, getFriendList, modifyFriendName } from './slice/FriendSlice';
import { toast } from 'react-toastify';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';




function FriendList() {

    const [t, i18n] = useTranslation("global");

    const [frndList, setfrndList] = useState([]);
    const [frndName, setFrndName] = useState();
    const [isActive, setIsActive] = useState(true);
    const dispatch = useDispatch()
    const [responsefList, setResponsefList] = useState("");
    const navigate = useNavigate();

    let result = sessionStorage.getItem("Username");
    result = JSON.parse(result);

    const onChangeFrndName = e => {
        setFrndName(e.target.value);
    };

    const hideShowDiv = (e) => {
        setIsActive(!isActive)
    }

    useEffect(() => {
        friendLists();
    }, []);

    const friendLists = () => {
        dispatch(getFriendList({ username: result }))
            .then((resp) => {
                // console.log(resp.payload.data)
                if (resp.payload.data.resMessage === "FRIEND LIST SUCCESSFULLY RETRIEVE") {
                    setfrndList(resp.payload.data.crbtProfile.friendList)
                    setResponsefList(resp.payload.data.resMessage)
                }
                else {
                    setResponsefList(resp.payload.data.resMessage)
                }
            })
            .catch((error) => {
                console.error(error);
                alert(error);
            });
    }

    const modifyFrndName = (fmsisdn) => {
        dispatch(modifyFriendName({ username: result, fmsisdn: fmsisdn, frndName: frndName }))
            .then((resp) => {
                // console.log(resp.payload.data)
                if (resp.payload.data.resMessage === "FRIEND NAME HAS BEED CHANGED") {
                    friendLists();
                    setIsActive(true)
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error(error);
            });
    }


    const deleteFriendNumber = (fmsisdn) => {
        dispatch(deleteFriend({ username: result, fmsisdn: fmsisdn }))
            .then((resp) => {
                // console.log(resp.payload.data)
                if (resp.payload.data.resMessage === "FRIEND DELETED SUCCESSFULLY") {
                    alert(resp.payload.data.resMessage);
                    friendLists();
                    setIsActive(true)
                }
                // else {
                //     alert("Error while deleting friend number")

                // }
            })
            .catch((error) => {
                console.error(error);
                toast.error(error);
            });
    }



if ((sessionStorage.getItem("Username") !== null) && (sessionStorage.getItem("Username") !== "")) {
    return (
        <div className='container'>
            <div className='redirect_container'>
                <Link to={"/crbt/freindSetting"} className=" d-flex justify-content-end p-2" style={{ textDecoration: 'none' }}>
                    <Button className='text-white border border-1' style={{ backgroundColor: 'transparent' }}>
                        {t("sideMenu.friendSetting.back")}
                    </Button>
                </Link>
                {/* <h3 style={{ textAlign: 'center' }}>Current Tone Setting</h3> */}
                <div className='d-flex justify-content-center'>
                    {
                        responsefList === "FRIEND LIST SUCCESSFULLY RETRIEVE"
                            ?
                            <TableContainer>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow className='text-white'>
                                            <TableCell align="center" className='text-white' style={{ fontWeight: 'bolder' }}>{t("sideMenu.friendSetting.frndNumber")}</TableCell>
                                            <TableCell align="center" className='text-white' style={{ fontWeight: 'bolder' }}>{t("sideMenu.friendSetting.frndName")}</TableCell>
                                            <TableCell align="center" className='text-white' style={{ fontWeight: 'bolder' }}>{t("sideMenu.friendSetting.edit")}</TableCell>
                                            <TableCell align="center" className='text-white' style={{ fontWeight: 'bolder' }}>{t("sideMenu.friendSetting.delete")}</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody >
                                        {frndList.map((getFrndList) => (
                                            <TableRow key={getFrndList.id} >
                                                <TableCell align="center" className='text-white'>{(getFrndList.fmsisdn)}</TableCell>
                                                <TableCell align="center" className='text-white'>
                                                    {isActive
                                                        ?
                                                        <p className='text-center' id="frnd"> {(getFrndList.friendName)} </p>
                                                        :
                                                        <input name="password" className='text-center border border-0 my-2' id="days" defaultValue={getFrndList.friendName} onChange={onChangeFrndName}
                                                        />
                                                    }
                                                </TableCell>
                                                <TableCell align="center" className='text-white'>
                                                    {
                                                        isActive ?
                                                            <Button id="days" className="border border-0 text-white" style={{ backgroundColor: 'transparent' }}
                                                                onClick={(e) => { hideShowDiv(e) }}>
                                                                <BorderColorSharpIcon />
                                                            </Button>
                                                            :
                                                            <Button id="days" className="border border-0 text-white" style={{ backgroundColor: 'transparent' }}
                                                                onClick={() => { modifyFrndName(getFrndList.fmsisdn) }}>
                                                                <SendSharpIcon />
                                                            </Button>
                                                    }
                                                </TableCell>
                                                <TableCell align="center" >
                                                    <Button onClick={() => { deleteFriendNumber(getFrndList.fmsisdn) }} className="border border-0 text-white" style={{ backgroundColor: 'transparent' }} >
                                                        <DeleteForeverSharpIcon />
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                        }
                                    </TableBody >

                                </Table>
                            </TableContainer>
                            :
                            <div className='d-flex text-white justify-content-center my-5 '>
                                <h4 className='border border-1 p-2 rounded'>
                                    {responsefList}
                                </h4>
                            </div>
                    }
                    {/* 
                    <table className="tonesettingHeading " style={{ backgroundColor: 'rgba(0,0,0,0.3)' }}>
                        <thead>
                            <tr style={{ color: 'yellow' }}>
                                <th>Friend's Number</th>
                                <th>Friend's Name</th>
                                <th>Edit </th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {frndList.map((getFrndList) => (
                                <tr key={getFrndList.id}>
                                    <td>{(getFrndList.fmsisdn)}</td>
                                    <td className='d-flex justify-content-center my-2 ' >
                                        {isActive
                                            ?
                                            <p className='text-center' id="frnd"> {(getFrndList.friendName)} </p>
                                            :
                                            <input name="password" className='text-center border border-0 my-2' id="days" defaultValue={getFrndList.friendName} onChange={onChangeFrndName}
                                            />
                                        }
                                    </td>
                                    <td>

                                        {
                                            isActive ?
                                                <Button id="days" className="border border-0" style={{ backgroundColor: 'transparent' }}
                                                    onClick={(e) => { hideShowDiv(e) }}>
                                                    <BorderColorSharpIcon />
                                                </Button>
                                                :
                                                <Button id="days" className="border border-0" style={{ backgroundColor: 'transparent' }}
                                                    onClick={() => { modifyFriendName(getFrndList.fmsisdn) }}>
                                                    <SendSharpIcon />
                                                </Button>
                                        }
                                    </td>
                                    <td>
                                        <Button onClick={() => { deleteFriendNumber(getFrndList.fmsisdn) }} className="border border-0" style={{ backgroundColor: 'transparent' }} >
                                            <DeleteForeverSharpIcon />
                                        </Button>
                                    </td>
                                </tr>
                            ))
                            }
                        </tbody>
                    </table> */}
                </div >

            </div>
        </div >
    );
}
    else {
        navigate("/")
    }
}

export default FriendList;
