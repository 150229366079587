import { Route, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import CategoryList from "./allPages/categorypage/CategoryList";
import ViewRbtList from "./allPages/categorypage/ViewRbtList";
import FriendList from "./allPages/friendPage/FriendList";
import FriendSetting from "./allPages/friendPage/FriendSetting";
import GetStart from "./allPages/GetStart";
import GroupList from "./allPages/groupPage/GroupList";
import GroupSetting from "./allPages/groupPage/GroupSetting";
import HomeCategoryData from "./allPages/homepage/HomeCategoryData";
import MyTones from "./allPages/libraryPage/MyTones";
import SetRbt from "./allPages/libraryPage/SetRbt";
import Loginpage from "./allPages/loginAndSignup/Loginpage";
import SignupPage from "./allPages/loginAndSignup/SignupPage";
import ChangePassword from "./allPages/password/ChangePassword";
import ToneSetting from "./allPages/tonesttingPage/ToneSetting";
import Layout from "./Layout";


const Router = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route exact path="/" element={< GetStart />} />
            <Route exact path="/login" element={< Loginpage />} />
            <Route exact path="/signUp" element={< SignupPage />} />
            <Route exact path='/crbt' element={<Layout />} >
                <Route path='/crbt/home' element={<HomeCategoryData />} />
                <Route path='/crbt/categoryList' element={<CategoryList />} />
                <Route path='/crbt/search/view/:id' element={<ViewRbtList />} />
                <Route path='/crbt/library' element={<MyTones />} />
                <Route path='/crbt/toneSetting' element={<ToneSetting />} />
                <Route path='/crbt/freindSetting' element={<FriendSetting />} />
                <Route path='/crbt/freind-list' element={<FriendList />} />
                <Route path='/crbt/setrbt/:id' element={<SetRbt />} />
                <Route path='/crbt/groupSetting' element={<GroupSetting />} />
                <Route path='/crbt/group-list' element={<GroupList />} />

                <Route path='/crbt/changePassword' element={<ChangePassword />} />


            </Route>
        </>
    ), {basename:'/web'}
)

export default Router;