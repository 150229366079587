import { Card } from '@mui/material';
import React, { Component, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import RbtOfCategory from './RbtOfCategory';
import { category } from './slice/CatSlice';
import ReactLoading from "react-loading";



function HomeCategoryData() {

    const [categoryList, setCategoryList] = useState([]);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    // const seletor = useSelector();

    let result = sessionStorage.getItem("Username");
    result = JSON.parse(result);
    // console.log(result)
    const data = useSelector(state => state.category)

    useEffect(() => {
        dispatch(category({ username: result }))
            .then((resp) => {
                // console.log(resp.payload.data)
                if (resp.payload.data.resMessage === "CATEGORIES HAS BEEN FETCHED SUCCESSFULLY") {
                    setCategoryList(resp.payload.data.catList)
                }
            })
            .catch((error) => {
                // console.error(error);
                toast.error(error);
            });
    }, []);


if ((sessionStorage.getItem("Username") !== null) && (sessionStorage.getItem("Username") !== "")) {
    if (data.isLoading) {
        return (
            <div className='d-flex justify-content-center align-items-center'>
                <ReactLoading type="bars" color="white"
                    height={100} width={50} />
            </div>
        )
    }
    else {
        return (
            <div className='container'>
                <div className='d-flex'>
                    <div >
                        {categoryList?.slice(1, 3).map((getcat) => (
                            <div className='d-flex flex-column '>
                                <div className='d-flex'>
                                    <Card className=' border-bottom border-warning border-4 rounded-bottom text-warning' style={{ fontSize: '25px', fontStyle: 'italic', fontWeight: '800', backgroundColor: 'transparent' }}>
                                        <div >{getcat.catName} - {getcat.catId}</div>
                                    </Card>
                                </div>
                                <div>
                                    <RbtOfCategory data={getcat.catId} user={result} />
                                </div>
                            </div>
                        ))
                        }
                    </div>
                </div>
            </div >
        );
    }
   }
    else {
        navigate("/")
    }
}

export default HomeCategoryData;
