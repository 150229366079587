import { RemoveRedEye, VisibilityOff } from '@mui/icons-material';
import { Button } from '@mui/material';
import React, {  useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updatePassword } from './slice/PasswordSlice';






function ChangePassword() {

    // const [password, setPassword] = useState("");
    const [type, setType] = useState('password');
    const [oldType, setOldType] = useState('password');
    // const [confirmPassword, setConfirmPassword] = useState("");
    const [typeConfirm, setTypeConfirm] = useState('password');
    const [icon, setIcon] = useState(<VisibilityOff style={{ color: "black", width: '30px', height: '30px' }} />);
    // const [iconConf, setIconConf] = useState(<VisibilityOff style={{ color: "black", width: '30px', height: '30px' }} />);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    let username = sessionStorage.getItem("Username");
    username = JSON.parse(username);

    const [input, setInput] = useState({
        oldPass: '',
        password: '',
        confirmPassword: ''
    });

    const [error, setError] = useState({
        oldPass: '',
        password: '',
        confirmPassword: ''
    })

    const onInputChange = e => {
        const { name, value } = e.target;
        setInput(prev => ({
            ...prev,
            [name]: value
        }));
        validateInput(e);
    }

    const validateInput = e => {
        let { name, value } = e.target;
        setError(prev => {
            const stateObj = { ...prev, [name]: "" };

            switch (name) {
                case "oldPass":
                    if (!value) {
                        stateObj[name] = "Please enter Old Password.";
                    }
                    break;

                case "password":
                    if (!value) {
                        stateObj[name] = "Please enter Password.";
                    } else if (input.confirmPassword && value !== input.confirmPassword) {
                        stateObj["confirmPassword"] = "Password and Confirm Password does not match.";
                    } else {
                        stateObj["confirmPassword"] = input.confirmPassword ? "" : error.confirmPassword;
                    }
                    break;

                case "confirmPassword":
                    if (!value) {
                        stateObj[name] = "Please enter Confirm Password.";
                    } else if (input.password && value !== input.password) {
                        stateObj[name] = "Password and Confirm Password does not match.";
                    }
                    break;

                default:
                    break;
            }

            return stateObj;
        });
    }

    const handleToggleOldPass = () => {
        if (oldType === 'password') {
            setIcon(<RemoveRedEye />);
            setOldType('text')
        } else {
            setIcon(<VisibilityOff />)
            setOldType('password')
        }
    }

    const handleTogglePass = () => {
        if (type === 'password') {
            setIcon(<RemoveRedEye />);
            setType('text')
        } else {
            setIcon(<VisibilityOff />)
            setType('password')
        }
    }
    const handleToggleConfirm = () => {
        if (typeConfirm === 'password') {
            setIcon(<RemoveRedEye />);
            setTypeConfirm('text')
        } else {
            setIcon(<VisibilityOff />)
            setTypeConfirm('password')
        }
    }

    const logout = () => {
        sessionStorage.removeItem("Username");
        // sessionStorage.clear();
        navigate("/login");
    }

    const handleChangePassword = () => {
        if (input.oldPass === "") {
            alert("Please First Enter Old Password")
        }
        if (input.password === "") {
            alert("Please Enter Password")
        }
        else if (input.confirmPassword === "") {
            alert("Please Enter Confirm Password")
        }
        else {
            dispatch(updatePassword({ username: username, password: input.oldPass, newPassword: input.password }))
                .then((resp) => {
                    // console.log(resp.payload.data.resMessage)
                    if (resp.payload.data.resMessage === "PASSWORD_UPDATE_SUCCESS") {
                        // alert("Your New Password Is :=>  " + input.password)
                        alert("Password Updated Successfully")
                        logout();
                    } else {
                        alert(resp.payload.data.resMessage)
                    }
                })
                .catch((error) => {
//                    console.error(error);
                     alert(error)
                });
//            console.log("Password == " + input.password + " & ConfirmPassword == " + input.confirmPassword)
        }
    }



    return (
        <div className='container'>
            <div className='d-flex justify-content-center align-items-center text-white'>
                <h1>
                    Change Password
                </h1>
            </div>
            <div className='d-flex justify-content-center flex-column align-items-center '>
                <div className='border border-1 rounded text-white p-5 m-3 ' style={{ width: '420px', backgroundColor: '#00000040' }}>
                    <div  className='my-4'>
                        <label className='' style={{ fontWeight: 'bold' }}>Old Password : </label>
                        <div className="d-flex align-items-center text-white border border-1 rounded  " style={{ width: '300px' }} >
                            <input
                                className='text-center border border-0 my-2 rounded text-white '
                                style={{ background: 'transparent', outline: 'none', fontWeight: 'bold' }}
                                type={oldType}
                                name="oldPass"
                                placeholder='Enter Old Password'
                                value={input.oldPass}
                                onChange={onInputChange}
                                onBlur={validateInput} />
                            <div className='d-flex justify-content-end mx-5'>
                                <span className="d-flex justify-content-end " onClick={handleToggleOldPass}>
                                    {
                                        oldType === "password"
                                            ?
                                            <RemoveRedEye />
                                            :
                                            <VisibilityOff />
                                    }
                                </span>
                            </div>
                        </div>
                    </div>
                    <div>
                        <label className='' style={{ fontWeight: 'bold' }}>New Password : </label>
                        <div className="d-flex align-items-center text-white border border-1 rounded  " style={{ width: '300px' }} >
                            <input
                                className='text-center border border-0 my-2 rounded text-white '
                                style={{ background: 'transparent', outline: 'none', fontWeight: 'bold' }}
                                type={type}
                                name="password"
                                placeholder='Enter Password'
                                value={input.password}
                                onChange={onInputChange}
                                onBlur={validateInput} />
                            <div className='d-flex justify-content-end mx-5'>
                                <span className="d-flex justify-content-end " onClick={handleTogglePass}>
                                    {
                                        type === "password"
                                            ?
                                            <RemoveRedEye />
                                            :
                                            <VisibilityOff />
                                    }
                                </span>
                            </div>
                        </div>
                        <div className='d-flex'>
                            {error.password && <span className='err text-white'>{error.password}</span>}
                        </div>
                    </div>
                    <div className='my-4'>
                        <label className='' style={{ fontWeight: 'bold' }}>Confirm Password : </label>
                        <div className="d-flex align-items-center text-white border border-1 rounded " style={{ width: '300px' }} >
                            <input
                                className='text-center border border-0 my-2 rounded text-white'
                                style={{ background: 'transparent', outline: 'none', fontWeight: 'bold' }}
                                type={typeConfirm}
                                name="confirmPassword"
                                placeholder='Enter Confirm Password'
                                value={input.confirmPassword}
                                onChange={onInputChange}
                                onBlur={validateInput} />
                            <div className='d-flex justify-content-end mx-5'>
                                <span className="d-flex justify-content-end " onClick={handleToggleConfirm}>
                                    {
                                        typeConfirm === "password"
                                            ?
                                            <RemoveRedEye />
                                            :
                                            <VisibilityOff />
                                    }
                                </span>
                            </div>
                        </div>
                        <div className='d-flex'>
                            {error.confirmPassword && <span className='err'>{error.confirmPassword}</span>}
                        </div>
                    </div>
                    <div className='d-flex justify-content-center mt-2'>
                        <Button className='border border-1 text-white mx-4' onClick={handleChangePassword}>Submit</Button>
                    </div>
                </div>
            </div>
            {/* <div className='d-flex justify-content-center flex-column align-items-center border border-1 w-50 rounded '>
                <div className='d-flex flex-column text-white  justify-content-center'>
                    <div className='d-fle'>
                        <label className='mx-5' style={{ fontWeight: 'bold' }}>New Password : </label>
                    </div>
                    <div className="my-4  d-flex align-items-center text-white border border-1 rounded " >
                        <input
                            className='text-center border border-0 my-2 rounded text-white'
                            style={{ background: 'transparent', outline: 'none' }}
                            type={type}
                            name="password"
                            placeholder='Enter Password'
                            value={input.password}
                            onChange={onInputChange}
                            onBlur={validateInput} />
                        <div className='d-flex justify-content-end mx-3'>
                            <span className="d-flex justify-content-end " onClick={handleTogglePass}>
                                {
                                    type === "password"
                                        ?
                                        <RemoveRedEye />
                                        :
                                        <VisibilityOff />
                                }
                            </span>
                        </div>
                    </div>
                    {error.password && <span className='err text-white'>{error.password}</span>}
                </div>
                <div className='d-flex text-white align-items-center justify-content-center'>
                    <div className='d-flex'>
                        <label className='mx-5' style={{ fontWeight: 'bold' }}>Confirm Password : </label>
                        <div className="my-4 d-flex align-items-center text-white border border-1 rounded " >
                            <input
                                className='text-center border border-0 my-2 rounded text-white'
                                style={{ background: 'transparent', outline: 'none' }}
                                type={typeConfirm}
                                name="confirmPassword"
                                placeholder='Enter Confirm Password'
                                value={input.confirmPassword}
                                onChange={onInputChange}
                                onBlur={validateInput} />
                            <div className='d-flex justify-content-end mx-3'>
                                <span className="d-flex justify-content-end " onClick={handleToggleConfirm}>
                                    {
                                        typeConfirm === "password"
                                            ?
                                            <RemoveRedEye />
                                            :
                                            <VisibilityOff />
                                    }
                                </span>
                            </div>
                        </div>
                    </div>
                    <div>
                        {error.confirmPassword && <span className='err'>{error.confirmPassword}</span>}
                    </div>
                </div>
                <div className='d-flex justify-content-center my-2'>
                    <Button className='border border-1 text-white mx-4' onClick={handleChangePassword}>Submit</Button>
                </div>
            </div> */}

            {/* <div className='d-flex justify-content-around'>
                <div className='d-flex text-white align-items-center'>
                    <label className='mx-4' style={{ fontWeight: 'bold' }}>New Password : </label>
                    <div className="my-4  d-flex align-items-center text-white border border-1 rounded " >
                        <input
                            className='text-center border border-0 my-2 rounded text-white'
                            style={{ background: 'transparent', outline: 'none' }}
                            type={type}
                            name="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            autoComplete="current-password"
                        />
                        <div className='d-flex justify-content-end mx-3'>
                            <span className="d-flex justify-content-end " onClick={handleTogglePass}>
                                {
                                    type === "password"
                                        ?
                                        <RemoveRedEye />
                                        :
                                        <VisibilityOff />
                                }
                            </span>
                        </div>
                    </div>
                </div>
                <div className='d-flex text-white align-items-center'>
                    <label className='mx-4' style={{ fontWeight: 'bold' }}>Confirm Password : </label>
                    <div className="my-4  d-flex align-items-center text-white border border-1 rounded " >
                        <input
                            className='text-center border border-0 my-2 rounded text-white'
                            style={{ background: 'transparent', outline: 'none' }}
                            type={typeConfirm}
                            name="confirmPassword"
                            placeholder="Password"
                            value={confirmPassword}
                            onChange={handleConfirmPassword}
                            autoComplete="current-password"
                        />
                        <div className='d-flex justify-content-end mx-3'>
                            <span className="d-flex justify-content-end " onClick={handleToggleConfirm}>
                                {
                                    typeConfirm === "password"
                                        ?
                                        <RemoveRedEye />
                                        :
                                        <VisibilityOff />
                                }
                            </span>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    );
}

export default ChangePassword;




