import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import firstImg from './images/getstartlogo.png';
import { useTranslation } from 'react-i18next';

function GetStart() {

    const navigate = useNavigate();
    const [langSetting, setLangSetting] = useState("");
    const [t, i18n] = useTranslation("global");

    const onGetStart = () => {
        navigate('/login');
    }
 

    const handleChangeLanguage = (lang) => {
        sessionStorage.setItem("lang", lang)
        const sessionlang = sessionStorage.getItem("lang");
        i18n.changeLanguage(sessionlang)
        setLangSetting((pre) => ({ ...pre, lang }));

    }

    return (
        <div className='container'  >
            <div className='d-flex justify-content-center' style={{height:'100vh'}}>
                <div className='d-flex justify-content-center align-items-center text-white flex-column '>
                    <div className=''>
                        <img style={{ width: "120px", height: "120px", padding: 0 }} src={firstImg} />
                    </div>
                    <div className='d-flex flex-column justify-content-center align-items-center '>
                        <h1 style={{ fontSize: "6em", padding: 0 }}>
                        {t("welcome.title")}
                        </h1>
                        <h1 className='p-0 fs-1'> {t("getstartPage.crbtTune")} </h1>
                        <h3 className='p-0'>  {t("getstartPage.together")} </h3>
                        <div className='d-flex align-items-center'>
                            <Button className='text-white border-bottom ' onClick={() => { handleChangeLanguage("en") }}>English</Button>
                            <div className='mx-2'>|</div>
                            <Button className='text-white border-bottom ' onClick={() => { handleChangeLanguage("ar") }}>Arabic</Button>
                        </div>
                        <Button className='text-white border border-1 px-4 my-4' onClick={onGetStart}>{t("getstartPage.btn")}</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GetStart;
