import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';

const initialState = {
    data: {},
    isLoading: true,
    error: false
}
// http://192.168.1.112:8898/Friend/AddFriend?msisdn=' + result + '&service=CRBT&interface=W&langId=1&fmsisdn=' + fmsisdn + '&friendName=' + nickName + '&subType=P

export const addFriend = createAsyncThunk("addFriend", async ({ username: username, fmsisdn: fmsisdn, nickName: nickName }) => {
    const response = axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/Profile/ToneSetting?msisdn=${username}&service=${process.env.REACT_APP_SERVICE}&interface=${process.env.REACT_APP_INTERFACES}&langId=${process.env.REACT_APP_LANGID}&fmsisdn=${fmsisdn}&friendName=${nickName}&subType=P`,
        // headers: {
        //     "Authorization": `Bearer ${data.token}`
        // },
    })
    // console.log(response)
    return response;
})

// http://192.168.1.112:8898/Group/ShowAllGroups?msisdn=" + result.username + "&service=CRBT&interface=W&langId=1

export const getGroupList = createAsyncThunk("getGroupList", async ({ username: username }) => {
    const response = axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/Group/ShowAllGroups?msisdn=${username}&service=${process.env.REACT_APP_SERVICE}&interface=${process.env.REACT_APP_INTERFACES}&langId=${process.env.REACT_APP_LANGID}`,
        // headers: {
        //     "Authorization": `Bearer ${data.token}`
        // },
    })
    // console.log(response)
    return response;
})


// http://192.168.1.112:8898/Group/AddGroup?msisdn=' + result.username + '&service=CRBT&interface=W&langId=1&groupName=' + groupName + '&groupMembers=' + groupMembers + '&subType=P
export const addGroup = createAsyncThunk("addGroup", async ({ username: username, groupName: groupName, groupMembers: groupMembers }) => {
    const response = axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/Group/AddGroup?msisdn=${username}&service=${process.env.REACT_APP_SERVICE}&interface=${process.env.REACT_APP_INTERFACES}&langId=${process.env.REACT_APP_LANGID}&groupName=${groupName}&groupMembers=${groupMembers}&subType=P`,
        // headers: {
        //     "Authorization": `Bearer ${data.token}`
        // },
    })
    console.log(response)
    return response;
})


export const modifyGroup = createAsyncThunk("modifyGroup", async ({ username: username, fmsisdn: fmsisdn, frndName: frndName }) => {
    const response = axios({
        method: 'put',
        url: `${process.env.REACT_APP_API_URL}/Friend/RenameFriend?msisdn=${username}&service=${process.env.REACT_APP_SERVICE}&interface=${process.env.REACT_APP_INTERFACES}&langId=${process.env.REACT_APP_LANGID}&fmsisdn=${fmsisdn}&friendName=${frndName}&subType=P`,
        // headers: {
        //     "Authorization": `Bearer ${data.token}`
        // },
    })
    console.log(response)
    return response;
})


export const deleteGroup = createAsyncThunk("deleteGroup", async ({ username: username, groupId: groupId }) => {
    const response = axios({
        method: 'delete',
        url: `${process.env.REACT_APP_API_URL}/Group/DeleteGroup?msisdn=${username}&service=${process.env.REACT_APP_SERVICE}&interface=${process.env.REACT_APP_INTERFACES}&langId=${process.env.REACT_APP_LANGID}&groupId=${groupId}&subType=P`,
        // headers: {
        //     "Authorization": `Bearer ${data.token}`
        // },
    })
    // console.log(response)
    return response;
})




export const GroupSlice = createSlice({
    name: 'groupSlice',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(getGroupList.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.error = false
            state.isLoading = false
        })
        builder.addCase(getGroupList.pending, (state) => {
            state.error = false
            state.isLoading = true
        })
        builder.addCase(getGroupList.rejected, (state) => {
            state.error = true
            state.isLoading = false
        })

        builder.addCase(addGroup.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.error = false
            state.isLoading = false
        })
        builder.addCase(addGroup.pending, (state) => {
            state.error = false
            state.isLoading = true
        })
        builder.addCase(addGroup.rejected, (state) => {
            state.error = true
            state.isLoading = false
        })

        builder.addCase(deleteGroup.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.error = false
            state.isLoading = false
        })
        builder.addCase(deleteGroup.pending, (state) => {
            state.error = false
            state.isLoading = true
        })
        builder.addCase(deleteGroup.rejected, (state) => {
            state.error = true
            state.isLoading = false
        })
    }
})

export default GroupSlice.reducer