import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';

const initialState = {
    data: {},
    // isLoading: true,
    error: false
}

// http://192.168.1.112:8898/Profile/ToneSetting?msisdn=" + result.username + "&service=CRBT&interface=W&langId=1

export const toneSet = createAsyncThunk("toneSet", async ({ username }) => {
    const response = axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/Profile/ToneSetting?msisdn=${username}&service=${process.env.REACT_APP_SERVICE}&interface=${process.env.REACT_APP_INTERFACES}&langId=${process.env.REACT_APP_LANGID}`,
        // headers: {
        //     "Authorization": `Bearer ${data.token}`
        // },
    })
    // console.log(response)
    return response;
})

export const ToneSettingSlice = createSlice({
    name: 'toneSettingSlice',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(toneSet.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.error = false
            state.isLoading = false
        })
        builder.addCase(toneSet.pending, (state) => {
            state.error = false
            state.isLoading = true
        })
        builder.addCase(toneSet.rejected, (state) => {
            state.error = true
            state.isLoading = false
        })
    }
})

export default ToneSettingSlice.reducer