import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';

const initialState = {
    data: {},
    isLoading: true,
    error: false
}

export const loginUser = createAsyncThunk("loginUser", async ({ username: username, password: password }) => {
    const response = axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/Authenticate/USERLOGIN?msisdn=${username}&service=${process.env.REACT_APP_SERVICE}&interface=${process.env.REACT_APP_INTERFACES}&langId=${process.env.REACT_APP_LANGID}&password=${password}`,
        // headers: {
        //     "Authorization": `Bearer ${data.token}`
        // },
    })
    // console.log(response)
    return response;
})


// http://192.168.1.112:8890/CrbtAPI/Content/ProfileData?msisdn=2203010007&service=CRBT&interface=W&langId=1&countryCode=220
export const profileData = createAsyncThunk("profileData", async ({ username: username }) => {
    const response = axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/Content/ProfileData?msisdn=${username}&service=${process.env.REACT_APP_SERVICE}&interface=${process.env.REACT_APP_INTERFACES}&langId=${process.env.REACT_APP_LANGID}&countryCode=${process.env.REACT_APP_COUNTRY_CODE_SUDATEL}`,
        // headers: {
        //     "Authorization": `Bearer ${data.token}`
        // },
    })
    // console.log(response)
    return response;
})




export const LoginSlice = createSlice({
    name: 'login',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(loginUser.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.error = false
            state.isLoading = false
        })
        builder.addCase(loginUser.pending, (state) => {
            state.error = false
            state.isLoading = true
        })
        builder.addCase(loginUser.rejected, (state) => {
            state.error = true
            state.isLoading = false
        })

        builder.addCase(profileData.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.error = false
            state.isLoading = false
        })
        builder.addCase(profileData.pending, (state) => {
            state.error = false
            state.isLoading = true
        })
        builder.addCase(profileData.rejected, (state) => {
            state.error = true
            state.isLoading = false
        })
    }
})

export default LoginSlice.reducer