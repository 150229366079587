import { configureStore } from '@reduxjs/toolkit'
import CategoryData from '../allPages/categorypage/slice/CategoryData'
import FriendSlice from '../allPages/friendPage/slice/FriendSlice'
import GroupSlice from '../allPages/groupPage/slice/GroupSlice'
import CatSlice from '../allPages/homepage/slice/CatSlice'
import RbtofCatSlice from '../allPages/homepage/slice/RbtofCatSlice'
import MyTonesLibrary from '../allPages/libraryPage/slice/MyTonesLibrary'
import RbtSetSlice from '../allPages/libraryPage/slice/RbtSetSlice'
import LoginSlice from '../allPages/loginAndSignup/slice/LoginSlice'
import SignUpSlice from '../allPages/loginAndSignup/slice/SignUpSlice'
import ToneSettingSlice from '../allPages/tonesttingPage/slice/ToneSettingSlice'

export const store = configureStore({
    reducer: {
        login: LoginSlice,
        category: CatSlice,
        rbtTunes: RbtofCatSlice,
        categoryData: CategoryData,
        toneSettingSlice: ToneSettingSlice,
        friendSlice: FriendSlice,
        groupSlice: GroupSlice,
        rbtSetSlice: RbtSetSlice,
        myTonesLibrary: MyTonesLibrary,
        signupSlice: SignUpSlice

    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
    devTools: false
})


