import React, { Component, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import BorderColorSharpIcon from '@mui/icons-material/BorderColorSharp';
import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp';
import { useDispatch } from 'react-redux';
import { toneSet } from './slice/ToneSettingSlice';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';




function ToneSetting() {

    const [t, i18n] = useTranslation("global");

    const [myTone, setMyTone] = useState([]);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [respTones, setRespTones] = useState("")
    let result = sessionStorage.getItem("Username");
    result = JSON.parse(result);

    useEffect(() => {
        dispatch(toneSet({ username: result }))
            .then((resp) => {
                // console.log(resp.payload.data)
                if (resp.payload.data.resMessage === "Tone Setting details fetched successfully") {
                    setMyTone(resp.payload.data.crbtProfile.toneSetting)
                    setRespTones(resp.payload.data.resMessage)
                }
                else {
                    setRespTones(resp.payload.data.resMessage)
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error(error);
            });
    }, []);



    if ((sessionStorage.getItem("Username") !== null) && (sessionStorage.getItem("Username") !== "")) {
        return (
            <div className='container'>
                <div className='d-flex flex-column justify-content-center text-white'>
                    <h3 style={{ textAlign: 'center' }}>
                        {t("sideMenu.toneSetting.heading")}
                    </h3>
                    <div>
                        {
                            respTones === "Tone Setting details fetched successfully"
                                ?
                                <TableContainer>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow className='text-white'>
                                                <TableCell align="center" className='text-white' style={{ fontWeight: 'bolder' }}>{t("sideMenu.toneSetting.types")}</TableCell>
                                                <TableCell align="center" className='text-white' style={{ fontWeight: 'bolder' }}>{t("sideMenu.toneSetting.msisdn")}</TableCell>
                                                <TableCell align="center" className='text-white' style={{ fontWeight: 'bolder' }}>{t("sideMenu.toneSetting.toneTitle")}</TableCell>
                                                <TableCell align="center" className='text-white' style={{ fontWeight: 'bolder' }}>{t("sideMenu.toneSetting.days")}</TableCell>
                                                <TableCell align="center" className='text-white' style={{ fontWeight: 'bolder' }}>{t("sideMenu.toneSetting.edit")}</TableCell>
                                                {/* <TableCell align="center" className='text-white' style={{ fontWeight: 'bolder' }}>DELETE</TableCell> */}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody >
                                            {myTone.map((getmytone) => (
                                                <TableRow key={getmytone.rbtCode} >
                                                    <TableCell align="center" className='text-white'>{(getmytone.settingFor)}</TableCell>
                                                    <TableCell align="center" className='text-white'>{(getmytone.friendName)}({(getmytone.fmsisdn)})</TableCell>
                                                    <TableCell align="center" className='text-white'>{(getmytone.rbtName)} - {(getmytone.rbtCode)}</TableCell>
                                                    <TableCell align="center" className='text-white'>{(getmytone.days)}</TableCell>
                                                    <TableCell align="center" >
                                                        <Link to={"/crbt/setrbt/" + getmytone.rbtCode} state={getmytone} className='text-white'>
                                                            <BorderColorSharpIcon />
                                                        </Link>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                            }
                                        </TableBody >
                                    </Table>
                                </TableContainer>
                                :
                                <div className='d-flex text-white justify-content-center my-5 '>
                                    <h4 className='border border-1 p-2 rounded'>
                                        {respTones}
                                    </h4>
                                </div>
                        }
                    </div>
                </div>
            </div >
        );
    }
    else {
        navigate("/")
    }
}

export default ToneSetting;
