import { Button } from '@mui/material';
import React, { Component, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addGroup } from './slice/GroupSlice';

function GroupSetting() {
    const [groupName, setGroupName] = useState("");
    const [groupMembers, setGroupMembers] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let result = sessionStorage.getItem("Username");
    result = JSON.parse(result);


    const handleGroupName = (e) => {
        setGroupName(e.target.value);
    }

    const handleGroupMembers = (e) => {
        setGroupMembers(e.target.value)
    }


    const onSubmit = () => {
        let request = {
            "msisdn": result.username,
            "service": "CRBT",
            "interface": "W",
            "langId": 1,
            "groupName": groupName,
            "groupMembers": groupMembers,
            "subType": "P"
        }

        console.log(request);
        dispatch(addGroup({ username: result, groupName: groupName, groupMembers: groupMembers }))
            .then((resp) => {
                console.log(resp.payload.data)
                if (resp.payload.data.resMessage === "Group created Successfully") {
                    navigate("/crbt/group-list");
                    alert(resp.payload.data.resMessage);
                }
                else {
                    alert(resp.payload.data.resMessage);
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error(error);
            });
    }




    return (
        <div className='container'>
            <div className='d-flex text-white flex-column'>
                <div className='d-flex justify-content-end m-2'>
                    <Link to="/crbt/group-list" style={{ textDecoration: 'none' }}>
                        <Button className='d-flex text-white border border-1' >View Group List</Button>
                    </Link>
                </div>
                <div className='d-flex' style={{ backgroundColor: 'rgba(0,0,0,0.3)' }}>
                    <div className='d-flex flex-column p-5' >
                        <div className='d-flex justify-content-around'>
                            <lable>Group Name</lable>
                            <input className='rounded border border-0 p-1' type="text" id="frndMsisdn" name="frndMsisdn" placeholder=' Enter Group Name ...' onChange={handleGroupName} />
                        </div>
                        <div className='d-flex  justify-content-around my-2 '>
                            <lable> Group Members </lable>
                            <textarea className='rounded border border-0 p-1' rows={4} cols={28} type="text" id="groupName" name="groupName" placeholder=' Group Members Phone Number ...' onChange={handleGroupMembers} />

                        </div>
                        <div className='d-flex align-items-center my-3'>
                            <div>
                                <b>Note : </b>Write phone numbers to be added here. Separate each phone number by a space or write each phone number on new line.
                                <br />
                                <b>Note : </b>Please add your friend's mobile number with area code.
                            </div>
                            <div className='d-flex text-white'>
                                <Button className='d-flex text-white border border-1 mx-5' outline onClick={onSubmit}>Submit</Button>
                                {/* <Button className='frndbtn mx-5' outline >Reset</Button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GroupSetting;