import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Card } from '@mui/material';
import { categoryList } from './slice/CategoryData';
import audioImage from '../images/imageServlet.png';
import ReactLoading from "react-loading";



function CategoryList() {

    const [category, setCategory] = useState([]);
    const dispatch = useDispatch()
    const navigate = useNavigate()

    let result = sessionStorage.getItem("Username");
    result = JSON.parse(result);

    const data = useSelector(state => state.categoryData)

    useEffect(() => {
        dispatch(categoryList({ username: result }))
            .then((resp) => {
                // console.log(resp.payload)
                if (resp.payload.data.resMessage === "CATEGORIES HAS BEEN FETCHED SUCCESSFULLY") {
                    setCategory(resp.payload.data.catList)
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error(error);
            });
    }, []);




if ((sessionStorage.getItem("Username") !== null) && (sessionStorage.getItem("Username") !== "")) {
    if (data.isLoading) {
        return (
            <div className='d-flex justify-content-center align-items-center    '>
                <ReactLoading type="bars" color="white"
                    height={100} width={50} />
            </div>
        )
    }
    else {


        return (
            <div className='container'>
                <div className='d-flex flex-wrap justify-content-between'>
                    {category.map((getcat) => (
                        <div className='d-flex flex-wrap mx-3 border border-2 border-primary rounded my-3'>
                            <Link to={`/crbt/search/view/${getcat.catId}`} state={{ data: getcat }} style={{ textDecoration: 'none' }}>
                                <Card className=' d-flex justify-content-center flex-column p-2 text-white' style={{ height: '180px', width: '180px', backgroundImage: `url(${audioImage})` }} >
                                    <div>{getcat.catName}</div>
                                    <div className='d-flex justify-content-end text-bottom' >{(getcat.rbtCodesOfCategoryCount)}</div>
                                </Card>
                            </Link>
                        </div>
                    ))
                    }
                </div>

            </div>

            // <div className=' d-flex mb-5 ' >
            //         {category.map((getcat, id) => (
            //             <div className=' d-flex justify-content-around p-3 m-4' id="main" key={id}>
            //                 <Link key={id.catlink} to={`/crbt/search/view/${getcat.catId}`} state={{ data: getcat }}>
            //                     <div className='d-flex overflow-hidden ' >
            //                         <div key={id.catCol}>
            //                             <Card key={id.catName}>
            //                                 {getcat.catName}
            //                             </Card>
            //                             <div key={id.catRbt} className='d-flex justify-content-end' >{(getcat.rbtCodesOfCategoryCount)}</div>
            //                         </div>
            //                     </div>
            //                 </Link>
            //             </div>

            //         ))
            //         }

            // </div >
        );
    }
}
    else {
        navigate("/")
    }
}

export default CategoryList;
