import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';

const initialState = {
    data: {},
    isLoading: true,
    error: false
}

export const categoryList = createAsyncThunk("categoryList", async ({ username }) => {
    const response = axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/Content/ShowCategories?msisdn=${username}&service=${process.env.REACT_APP_SERVICE}&interface=${process.env.REACT_APP_INTERFACES}&langId=${process.env.REACT_APP_LANGID}`,
        // headers: {
        //     "Authorization": `Bearer ${data.token}`
        // },
    })
    // console.log(response)
    return response;
})

export const CategoryData = createSlice({
    name: 'categoryData',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(categoryList.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.error = false
            state.isLoading = false
        })
        builder.addCase(categoryList.pending, (state) => {
            state.error = false
            state.isLoading = true
        })
        builder.addCase(categoryList.rejected, (state) => {
            state.error = true
            state.isLoading = false
        })
    }
})

export default CategoryData.reducer