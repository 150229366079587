import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import { store } from './store/Store';
import global_en from './languages/en/global.json';
import global_ar from './languages/ar/global.json';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';



i18next.init({
  fallbackLng: 'en',
  interpolation: { escapeValue: false },
  lng: sessionStorage.getItem("lang"),
  resources: {
    en: {
      global: global_en
    },
    ar: {
      global: global_ar
    }
  }
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <Provider store={store}>
        <App />
      </Provider>
    </I18nextProvider>
  </React.StrictMode>
);
