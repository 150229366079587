import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Logout from '@mui/icons-material/Logout';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { Link, useNavigate } from 'react-router-dom';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { Button } from '@mui/material';
import SearchBarComponent from './SearchBarComponent';
import SearchBarList from './SearchBarList';
import { useTranslation } from 'react-i18next';


function HeaderComponent(props) {

    const [t, i18n] = useTranslation("global");

    const msisdn = props.user;
    // console.log(msisdn)

    const navigate = useNavigate();
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [results, setResults] = useState([]);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    // const stringAvatar = (name) => {
    //     return {
    //         children: `${name.split(' ')[0][0]}`,
    //     };
    // }

    const logout = () => {
        sessionStorage.removeItem("Username");
        navigate("/login");
    }

    const changePass = () => {
        navigate("/crbt/changePassword");
    }



    return (
        <div className=' p-3 d-flex text-white justify-content-around  align-items-center '>
            <div className='d-flex'>
                <h1 className=''>{t("header.heading")}</h1>
            </div>
            <Toolbar className='d-flex text-white'>
                <div className='d-flex'>
                    <Box className='text-white'>
                        <Tooltip title={t("settignOpen.title")} >
                            <IconButton onClick={handleOpenUserMenu} >
                                <div style={{ color: 'white', fontSize: '16px' }}>{t("header.user")} : {msisdn}</div>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '40px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            <div className='d-flex flex-column'>
                                <MenuItem >
                                    <div className='d-flex justify-content-around allign-items-center'>
                                        <Button onClick={changePass} className="" style={{ color: "black" }}>
                                            <div>
                                                <ManageAccountsIcon />
                                            </div>
                                            <div className='mx-2'>
                                                {t("settignOpen.setting")}
                                            </div>
                                        </Button>
                                    </div>
                                </MenuItem>
                                <MenuItem >
                                    <div className='d-flex justify-content-around allign-items-center'>
                                        <Button style={{ color: "black" }} onClick={logout}>
                                            <div>
                                                <Logout />
                                            </div>
                                            <div className='mx-2'>
                                                {t("settignOpen.logout")}
                                            </div>
                                        </Button>
                                    </div>
                                </MenuItem>
                            </div>
                        </Menu>
                    </Box>
                </div>
            </Toolbar>
        </div>
    );
}

export default HeaderComponent;
