import { Button } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addFriend } from './slice/FriendSlice';

import { useTranslation } from 'react-i18next';

function FriendSetting() {


    const [t, i18n] = useTranslation("global");


    const [fmsisdn, setFmsisdn] = useState("");
    const [nickName, setNickName] = useState("");
    const dispatch = useDispatch()
    const navigate = useNavigate()

    let result = sessionStorage.getItem("Username");
    result = JSON.parse(result);

    const handleSubmit = () => {
        dispatch(addFriend({ username: result, fmsisdn: fmsisdn, nickName: nickName }))
            .then((resp) => {
                // console.log(resp.payload.data)
                if (resp.payload.data.resMessage === "FRIEND HAS BEEN ADDED SUCCESSFULLY") {
                    navigate("/crbt/freind-list");
                    alert(t("sideMenu.friendSetting.frndSuccess"));
                }
                else {
                    alert(resp.payload.data.resMessage);
                }
            })
            .catch((error) => {
                console.error(error);
                alert(error);
            });
    }


    const changeFriendNumber = (event) => {
        setFmsisdn(event.target.value);
    }

    const changeNickName = (event) => {
        setNickName(event.target.value)
    }


    if ((sessionStorage.getItem("Username") !== null) && (sessionStorage.getItem("Username") !== "")) {
        return (
            <div className='container'>
                <div className='d-flex justify-content-end m-2' >
                    <Link to="/crbt/freind-list">
                        <Button className='text-white border border-1 px-4' >
                            {t("sideMenu.friendSetting.viewFrndList")}
                        </Button>
                    </Link>
                </div>
                <div className='my-4 p-5' style={{ backgroundColor: 'rgba(0,0,0,0.3)' }}>
                    <div className='d-flex justify-content-around align-items-center'>
                        <div className='d-flex text-white flex-column '>
                            <lable>{t("sideMenu.friendSetting.phoneNumber")} :</lable>
                            <input className='rounded border border-0 p-1' type="number" placeholder=' Friend Phone Number ...'
                                value={fmsisdn} onChange={changeFriendNumber} />
                        </div>
                        <div className='d-flex text-white flex-column'>
                            <lable>{t("sideMenu.friendSetting.nickName")} :</lable>
                            <input className='rounded border border-0 p-1' type="text" placeholder=' Friend Nick Name ...'
                                value={nickName} onChange={changeNickName} />
                        </div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center text-white my-4'>
                        {t("sideMenu.friendSetting.note")}
                        <Button className='text-white border border-1 px-5' onClick={handleSubmit} >
                            {t("sideMenu.friendSetting.addFrind")}
                        </Button>
                        {/* <Button outline className='frndSubmit' style={{ marginLeft: '20px' }}>Reset</Button> */}
                    </div>
                </div>
            </div>
        );
    }
    else {
        navigate("/")
    }
}

export default FriendSetting;
