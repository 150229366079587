import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';

const initialState = {
    data: {},
    isLoading: true,
    error: false
}


// http://192.168.1.112:8890/CrbtAPI/Authenticate/CHANGEPASSWORD?msisdn=2203010007&service=CRBT&interface=W&langId=1&password=8371&newPass=8377

export const updatePassword = createAsyncThunk("updatePassword", async ({ username: username, password: password, newPassword: newPassword }) => {
    const response = axios({
        method: 'put',
        url: `${process.env.REACT_APP_API_URL}/Authenticate/CHANGEPASSWORD?msisdn=${username}&service=${process.env.REACT_APP_SERVICE}&interface=${process.env.REACT_APP_INTERFACES}&langId=${process.env.REACT_APP_LANGID}&oldpassword=${password}&newPass=${newPassword}`,
        // headers: {
        //     "Authorization": `Bearer ${data.token}`
        // },
    })
    // console.log(response)
    return response;
})







export const PasswordSlice = createSlice({
    name: 'password',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(updatePassword.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.error = false
            state.isLoading = false
        })
        builder.addCase(updatePassword.pending, (state) => {
            state.error = false
            state.isLoading = true
        })
        builder.addCase(updatePassword.rejected, (state) => {
            state.error = true
            state.isLoading = false
        })


    }
})

export default PasswordSlice.reducer