import { Button, Card } from '@mui/material';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { loginUser, profileData } from './slice/LoginSlice';
import ReactLoading from "react-loading";
import { useTranslation } from 'react-i18next';


function Loginpage() {

    const [t, i18n] = useTranslation("global");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [responseData, setResponseData] = useState([]);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const data = useSelector(state => state.login)

    const handleUserNumber = (e) => {
        setUsername(e.target.value)
    }

    const handlePassword = (e) => {
        setPassword(e.target.value)
    }

    const longinUser = () => {
        dispatch(loginUser({ username: username, password: password }))
            .then((resp) => {
                // console.log(resp.payload.data)
                if (resp.payload.data.resMessage === ("LOGIN_USER_SUCCESS")) {
                    dispatch(profileData({ username: username }))
                        .then((resp) => {
//                            console.log(resp.payload.data.crbtProfile)
                            if (resp.payload.data.resMessage === ("PROFILE CHECKED SUCCESSFULLY")) {
                                navigate("/crbt/home", { state: username })
                                sessionStorage.setItem("Username", JSON.stringify(username)); //setting value in session
                                let result = sessionStorage.getItem("Username");  //getting value of session
                                sessionStorage.setItem("SubType", JSON.stringify(resp.payload.data.crbtProfile.subType));
                                sessionStorage.setItem("AlbumList", JSON.stringify(resp.payload.data.crbtProfile.albumList));
//                              console.log(sessionStorage.getItem("SubType"))
                                result = JSON.parse(result);
                                // alert(resp.payload.data.resMessage);
                            }
                            else {
                                alert(resp.payload.data.resMessage);
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                            toast.error(error);
                        });
		     alert(t("loginPage.success"));
                }
                else {
                    alert(resp.payload.data.resMessage);
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error(error);
            });

    }

    return (
        <div className='container'>
            <div className='d-flex flex-column justify-content-center text-white' style={{ height: '100vh' }}>
		<div className='d-flex justify-content-start'>
                      <Link to={"/"} className=" d-flex justify-content-start p-2" style={{ textDecoration: 'none' }}>
                   	   <Button className='text-white border border-1' style={{ backgroundColor: 'transparent' }}>{t("sideMenu.friendSetting.back")}</Button>
               	      </Link>
		</div>
                <div className='d-flex flex-column justify-content-center align-items-center'>
                    <div className='d-flex justify-content-center flex-column align-items-center'>
                        <AccountCircleIcon />
                        <h5>
                            <p> {t("welcome.title")}</p>
                        </h5>
                    </div>
                    <div className='d-flex justify-content-center flex-column'>
                        <div>
                            <label>{t("loginPage.mobileNum")} : </label>
                            <input type="number" className='rounded border border-0 p-2 w-100' placeholder={t("loginPage.enterMb")} value={username} onChange={handleUserNumber} />
                        </div>
                        <div className='my-2'>
                            <label>{t("loginPage.password")} : </label>
                            <input type="password" className='rounded border border-0 p-2 w-100' placeholder={t("loginPage.entrPassword")} value={password} onChange={handlePassword} onKeyDown={(e) => {
                                if (e.key === "Enter")
                                    longinUser()
                            }} />
                        </div>

                    </div>
                    {/* <div className='d-flex justify-content-center text-white my-3'>
                        <Button className=' text-white' >Forget Password ?</Button>
                    </div> */}
                    <div className='d-flex justify-content-between my-4'>
                        <Button className='border border-1 text-white mx-4' onClick={longinUser}>
                            {t("loginPage.login")}  </Button>
                        <Link to="/signUp">
                            <Button className='border border-1 text-white mx-4' >
                                {t("signupPage.signup")}
                            </Button>
                        </Link>
                    </div>
                </div>

            </div>
        </div >
    );
}

export default Loginpage;
