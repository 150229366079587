import React, { Component, useEffect, useState } from 'react';
import SendSharpIcon from '@mui/icons-material/SendSharp';
import BorderColorSharpIcon from '@mui/icons-material/BorderColorSharp';
import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { deleteGroup, getGroupList } from './slice/GroupSlice';

function GroupList() {

    const [groupName, setGroupName] = useState("");
    const [groupList, setGroupList] = useState([]);
    // const [friendList, setFriendList] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isActive, setIsActive] = useState(true)
    let result = sessionStorage.getItem("Username");
    result = JSON.parse(result);

    const friendList = [
        "2203770173",
        "2203770172",
        "2203770157"
    ];

    const hideShowDiv = (e) => {
        setIsActive(!isActive)
    }

    const handleGroupName = (e) => {
        setGroupName(e.target.value);
    }



    useEffect(() => {
        dispatch(getGroupList({ username: result }))
            .then((resp) => {
                // console.log(resp.payload.data)
                if (resp.payload.data.resMessage === "Group list fetched successfully") {
                    setGroupList(resp.payload.data.crbtProfile.groupList)
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error(error);
            });
    }, []);

    const modifyGroupName = () => {
        console.log(groupName);
    }

    const deleteGroupById = (groupId) => {
        dispatch(deleteGroup({ username: result, groupId: groupId }))
            .then((resp) => {
                // console.log(resp.payload.data)
                if (resp.payload.data.resMessage === "Group Deleted Successfully") {
                    alert("Group Deleted Successfully")
                    setIsActive(true)
                    navigate("/crbt/group-list")
                    dispatch(getGroupList({ username: result }))
                        .then((resp) => {
                            // console.log(resp.payload.data)
                            if (resp.payload.data.resMessage === "Group list fetched successfully") {
                                setGroupList(resp.payload.data.crbtProfile.groupList)
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                            toast.error(error);
                        });
                }
                else {
                    alert("Error While Deleting Group")
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error(error);
            });
    }


    return (
        <div className='container'>
            <Link to={"/crbt/groupSetting"} className=" d-flex justify-content-end p-2" style={{ textDecoration: 'none' }}>
                <Button className='text-white border border-1' style={{ backgroundColor: 'transparent' }}>Back</Button>
            </Link>
            <div className='d-flex justify-content-center align-items-center my-4'>
                <TableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow className='text-white'>
                                <TableCell align="center" className='text-white' style={{ fontWeight: 'bolder' }}>Group's Id</TableCell>
                                <TableCell align="center" className='text-white' style={{ fontWeight: 'bolder' }}>Group's Name</TableCell>
                                <TableCell align="center" className='text-white' style={{ fontWeight: 'bolder' }}>Group's Member</TableCell>
                                <TableCell align="center" className='text-white' style={{ fontWeight: 'bolder' }}>EDIT</TableCell>
                                <TableCell align="center" className='text-white' style={{ fontWeight: 'bolder' }}>DELETE</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody >
                            {groupList.map((getGroupList) => (
                                <TableRow key={getGroupList.groupId} >
                                    <TableCell align="center" className='text-white'>{(getGroupList.groupId)}</TableCell>
                                    <TableCell align="center" className='text-white'>
                                        {isActive
                                            ?
                                            <p className='text-center' id="frnd"> {(getGroupList.groupName)} </p>
                                            :
                                            <input name="password" className='text-center border border-0 my-2' id="days" defaultValue={getGroupList.groupName} onChange={handleGroupName} />
                                        }
                                    </TableCell>
                                    <TableCell align="center" className='text-white'>
                                        {
                                            friendList?.map((list) => {
                                                return <p>{list}</p>
                                            })
                                        }
                                    </TableCell>
                                    <TableCell align="center">
                                        {
                                            isActive ?
                                                <Button id="days" className="text-white border border-0" style={{ backgroundColor: 'transparent' }}
                                                    onClick={(e) => { hideShowDiv(e) }}>
                                                    <BorderColorSharpIcon />
                                                </Button>
                                                :
                                                <Button id="days" className="text-white border border-0" style={{ backgroundColor: 'transparent' }}
                                                    onClick={modifyGroupName}>
                                                    <SendSharpIcon />
                                                </Button>
                                        }
                                    </TableCell>
                                    <TableCell align="center" >
                                        <Button onClick={() => (deleteGroupById(getGroupList.groupId))} className=" text-white border border-0" style={{ backgroundColor: 'transparent' }}>
                                            <DeleteForeverSharpIcon />
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))
                            }
                        </TableBody >
                    </Table>
                </TableContainer>



                {/* <table className="tonesettingHeading " style={{ backgroundColor: 'rgba(0,0,0,0.3)' }}>
                        <thead>
                            <tr style={{ color: 'yellow' }}>
                                <th>Group's Id</th>
                                <th>Group's Name</th>
                                <th>Group's Member</th>
                                <th>Edit </th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {groupList.map((getGroupList) => (
                                <tr key={getGroupList.groupId}>
                                    <td>{(getGroupList.groupId)}</td>
                                    <td>
                                        {isActive
                                            ?
                                            <p className='text-center' id="frnd"> {(getGroupList.groupName)} </p>
                                            :
                                            <input name="password" className='text-center border border-0 my-2' id="days" defaultValue={getGroupList.groupName} onChange={handleGroupName} />
                                        }
                                    </td>
                                    <td>
                                        {
                                            friendList?.map((list) => {
                                                return <p>{list}</p>
                                            })
                                        }
                                    </td>
                                    <td>

                                        {
                                            isActive ?
                                                <Button id="days" className="border border-0" style={{ backgroundColor: 'transparent' }}
                                                    onClick={(e) => { hideShowDiv(e) }}>
                                                    <BorderColorSharpIcon />
                                                </Button>
                                                :
                                                <Button id="days" className="border border-0" style={{ backgroundColor: 'transparent' }}
                                                    onClick={modifyGroupName}>
                                                    <SendSharpIcon />
                                                </Button>
                                        }
                                    </td>
                                    <td>
                                        <Button onClick={() => (deleteGroupById(getGroupList.groupId))} className="border border-0" style={{ backgroundColor: 'transparent' }}>
                                            <DeleteForeverSharpIcon />
                                        </Button>
                                    </td>
                                </tr>
                            ))
                            }
                        </tbody>
                    </table> */}

            </div >
        </div>
    );

}

export default GroupList;