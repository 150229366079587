import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';

const initialState = {
    data: {},
    isLoading: true,
    error: false
}


// http://192.168.1.112:8898/Purchase/SetRBT?msisdn=' + result.username + '&service=CRBT&interface=W&langId=1&planId=1&rbtCode=' + rbtCode + '&subType=P&settingFor='
//     + settingFor + '&fmsisdn=' + fmsisdn + '&sendInfoToFmsisdn=' + sendInfotoFmsisdn + '&date=' + date +
//     '&groupId=' + groupId + '&stHr=' + startHour.replace(/:/g, '') * 100 + '&endHr=' + endHour.replace(/:/g, '') * 100 + '&days=' + days

export const setRbt = createAsyncThunk("setRbt", async ({ username: username, req: data }) => {
    const response = axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/Purchase/SetRBT?msisdn=${username}&service=${process.env.REACT_APP_SERVICE}&interface=${process.env.REACT_APP_INTERFACES}&langId=${process.env.REACT_APP_LANGID}&${data}`,
        // headers: {
        //     "Authorization": `Bearer ${data.token}`
        // },
    })
    // console.log(response)
    return response;
})

export const RbtSetSlice = createSlice({
    name: 'rbtSetSlice',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(setRbt.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.error = false
            state.isLoading = false
        })
        builder.addCase(setRbt.pending, (state) => {
            state.error = false
            state.isLoading = true
        })
        builder.addCase(setRbt.rejected, (state) => {
            state.error = true
            state.isLoading = false
        })
    }
})

export default RbtSetSlice.reducer