import { Button, Checkbox, FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from '@mui/material';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getFriendList } from '../friendPage/slice/FriendSlice';
import { getGroupList } from '../groupPage/slice/GroupSlice';
import { setRbt } from './slice/RbtSetSlice';
import { useTranslation } from 'react-i18next';


function SetRbt() {

    const [t, i18n] = useTranslation("global");

    const location = useLocation();
    // console.log(location.state.rbtCode);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    let result = sessionStorage.getItem("Username");
    result = JSON.parse(result);

    const [rbtCode, setRbtCode] = useState(location.state.rbtCode);
    const [options, setOptions] = useState("");
    const [optionsF, setOptionsF] = useState("");
    const [friendList, setFriendList] = useState([]);
    const [groupList, setGroupList] = useState([]);
    const [settingFor, setSettingFor] = useState("");
    const [groupId, setGroupId] = useState("");
    const [settingFroFriend, setSettingFroFriend] = useState("");
    const [fmsisdn, setFmsisdn] = useState("");
    const [sendInfotoFmsisdn, setSendInfotoFmsisdn] = useState('0');
    const [date, setDate] = useState("");
    const [startHour, setStartHour] = useState(0);
    const [endHour, setEndHour] = useState(0);
    const [days, setDays] = useState([]);
    const [daysOfWeek, setDaysOfWeek] = useState([]);
    const [response, setResponse] = useState([]);
    const [disablData, setDisablData] = useState(true);

    // const daysName = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];
    // const daysVal = [1, 2, 3, 4, 5, 6, 7];




    useEffect(() => {
        dispatch(getFriendList({ username: result }))
            .then((resp) => {
                // console.log(resp.payload.data)
                if (resp.payload.data.resMessage === "FRIEND LIST SUCCESSFULLY RETRIEVE") {
                    setFriendList(resp.payload.data.crbtProfile.friendList)
                }
            })
            .catch((error) => {
                // console.error(error);
                toast.error(error);
            });

        dispatch(getGroupList({ username: result }))
            .then((resp) => {
                // console.log(resp.payload.data)
                if (resp.payload.data.resMessage === "Group list fetched successfully") {
                    setGroupList(resp.payload.data.crbtProfile.groupList)
                }
            })
            .catch((error) => {
                // console.error(error);
                toast.error(error);
            });

    }, [])


    const changesettingForGrpHandler = (event) => {
        setSettingFor(event.target.value)
        // console.log("setting For =  " + event.target.value)
        if (event.target.value.length > 0) {
            setDisablData(false);
        }
    }

    const changeGrpIdHandler = (event) => {
        // this.setState(checkedRadio: event.target.value);
        // let setF = document.getElementsByName('setForGrp');
        setGroupId(event.target.value)
        // this.setState({ groupId: event.target.value });
        // console.log("Group ID =>  " + event.target.value);
        // console.log("frnd  >>  " + this.state.optionsF.length);
    }
    const changesettingForFrndHandler = (event) => {
        setSettingFroFriend(event.target.value);
        // console.log("Friend Msisdn =>  " + JSON.stringify(event.target.value));
    }
    const changefmsisdnHandler = (event) => {
        setFmsisdn(event.target.value);
        // if (event.target.value < 11 || event.target.value > 15) {
        //     alert("Please enter valid MSISDN")
        // }
        // else {
        //     setFmsisdn(event.target.value);
        // }
        // console.log("Fmsisdn =>  " + event.target.value);

    }
    // handleDateChange = (e) => {
    //     this.setState({ date: e.target.value });
    //     console.log("Date Fromat  => " + e.target.value);
    // };
    const changedateHandler = (event) => {
        let date = event.target.value;
        let arr = date.split("-");
        let year = arr[0];
        let month = arr[1];
        let day = arr[2];
        let occassion = day + '-' + month + '-' + year;
        // console.log("dd/mm/yyyy  ==  " +date.split('-').join('/'));
        setDate(occassion)
        // console.log("Date =>  " + occassion);
    }
    const changedaysHandler = (event) => {
        // this.setState(initialState => ({
        //     days: !initialState.isAvocado,
        //   }));
        let val = [];
        let week = document.getElementsByName('week');
        // console.log("><<<<<<<<<<      " +week);
        for (let i = 0; i < week.length; i++) {
            if (week[i].checked === true) {
                val.push(week[i].value);
                // val = val.split(',').join('');
                // console.log("weeksss " + val.join(''));
                setDays(val.join(''));
            }
        }
    }
    const changestHrHandler = (event) => {
        setStartHour(event.target.value);
        const newHour = event.target.value;
        if (newHour >= 0 && newHour <= 23) {
            setStartHour(newHour);
        }
        // console.log("Satrting Hour  " + newHour);
    }
    const changeendHrHandler = (event) => {
        setEndHour(event.target.value);
        const newHour = event.target.value;
        if (newHour >= 0 && newHour <= 23) {
            setEndHour(newHour);
        }
        // console.log("End Hour  " + newHour);
    }
    const changesendInfoToFmsisdnHandler = (event) => {
        if (!event.target.checked) {
            setSendInfotoFmsisdn('0')
        }
        else {
            setSendInfotoFmsisdn('1')
        }
    }
    // changefullDayHandler = (event) => {
    //     this.setState({ fullDay: event.target.value });
    // }




    const submit = () => {
        // e.preventDefault();
        let responce = {
            "settingFor": settingFor,
            "fmsisdn": fmsisdn,
            "sendInfoToFmsisdn": sendInfotoFmsisdn,
            "date": date,
            "groupId": groupId,
            "stHr": startHour,
            "endHr": endHour,
            "days": days
        };
        // console.log('Responce => ' + JSON.stringify(responce));
        let data = 'planId=1&rbtCode=' + rbtCode + '&subType=P&settingFor='
            + settingFor + '&fmsisdn=' + fmsisdn + '&sendInfoToFmsisdn=' + sendInfotoFmsisdn + '&date=' + date +
            '&groupId=' + groupId + '&stHr=' + startHour * 100 + '&endHr=' + endHour * 100 + '&days=' + days;

        dispatch(setRbt({ username: result, req: data }))
            .then((resp) => {
                // console.log(resp.payload.data)
                if (resp.payload.data.resMessage === "RBT is set successfully") {
                    alert(resp.payload.data.resMessage);
                    navigate("/crbt/library");
                }
                else if (resp.payload.data.resMessage === "FRIEND'S NUMBER IS NOT VALID") {
                    alert(resp.payload.data.resMessage + "\nLength Must Be In Between 11 to 15");
                }
                else {
                    alert(resp.payload.data.resMessage);
                }
            })
            .catch((error) => {
                // console.error(error);
                toast.error(error);
            });
    }
    // fetch('http://192.168.1.112:8898/Purchase/SetRBT?msisdn=' + result.username + '&service=CRBT&interface=W&langId=1&planId=1&rbtCode=' + rbtCode + '&subType=P&settingFor='
    //     + settingFor + '&fmsisdn=' + fmsisdn + '&sendInfoToFmsisdn=' + sendInfotoFmsisdn + '&date=' + date +
    //     '&groupId=' + groupId + '&stHr=' + startHour.replace(/:/g, '') * 100 + '&endHr=' + endHour.replace(/:/g, '') * 100 + '&days=' + days,
    //     {
    //         method: 'POST'
    //     })
    //     .then(resp => resp.json())
    //     .then((data) => {
    //         console.log(data.resMessage);
    //         if (data.resMessage !== null) {
    //             alert(data.resMessage);
    //             // this.props.history.push("/crbt/home" );
    //         }
    //         else {
    //             alert(data.resMessage);
    //             // this.props.history.push("/login");
    //         }
    //     })
    //     .catch(err => console.log(err))

    // console.log("Response After Hit   = > ");




    const daysEntry = (event) => {
        const { value, checked } = event.target;
        // let { days } = this.state;

        let newDays = '';
        let inserted = false;
        let removed = false;

        for (let i = 0; i < days.length; i++) {
            const currentValue = days[i];
            if (parseInt(currentValue) > parseInt(value) && !inserted && checked) {
                newDays += value;
                inserted = true;
            }
            if (parseInt(currentValue) !== parseInt(value)) {
                newDays += currentValue;
            } else {
                removed = true; // Mark as removed
            }
        }

        // If value wasn't inserted and it wasn't removed (if it existed), add it to the end
        if (!inserted && !removed && checked && !newDays.includes(value)) {
            newDays += value;
        }

        // days.value = newDays;
        console.log(newDays)
        setDays(newDays)
    };






    // // const [hour, setHour] = useState('');
    // const [minute, setMinute] = useState('');

    // const handleHourChange = (e) => {
    //     const newHour = e.target.value;
    //     if (newHour >= 0 && newHour <= 23) {
    //         setHour(newHour);
    //     }
    // };







if ((sessionStorage.getItem("Username") !== null) && (sessionStorage.getItem("Username") !== "")) {
    return (
        <div className='container'>
            <div className=' d-flex flex-column text-white'>
                <div className='d-flex justify-content-around'>
                    <h4>
                        {t("setRBT.userSetting.title")}
                    </h4>
                    <div className='d-flex justify-content-around'>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="Set As Default"
                            name="radio-buttons-group"
                            id="radio" >
                            <FormControlLabel value="DEFAULT" control={<Radio style={{ color: 'white' }} />}
                                label={t("setRBT.userSetting.setAsDefault")} id="default"
                                onChange={(rb) => { setSettingFor(rb.target.value) }} />
                            <div className='d-flex justify-content-between my-2' >
                                <FormControlLabel id="setForGrp" name="setForGrp" value="GROUP"
                                    control={<Radio style={{ color: 'white' }} />} label={t("setRBT.userSetting.setForGroup")}
                                    onChange={changesettingForGrpHandler} />
                                <select id="setForGrpinput" className='text-dark p-2 border border-0 rounded'
                                    style={{ backgroundColor: 'white' }} name='setForGrpinput'
                                    value={groupId} onChange={changeGrpIdHandler}
                                    disabled={disablData}  >
                                    <option> {t("setRBT.userSetting.selectgrpId")} </option>
                                    {groupList.map((list) => {
                                        return <option>{list.groupName} ( {list.groupId} ) </option>
                                    })}
                                </select>
                            </div>
                            <div className='d-flex justify-content-between my-2' >
                                <FormControlLabel id="setForFrnd" name='setForFrnd' value="FRIEND"
                                    control={<Radio style={{ color: 'white' }} />} label={t("setRBT.userSetting.setForFriend")}
                                    onChange={(rb) => { setSettingFor(rb.target.value) }} />
                                <select id="setForFrnd" name="setForFrnd" className='text-dark p-2 border border-0 rounded' style={{ backgroundColor: 'white' }} onChange={changesettingForFrndHandler} >
                                    <option>  {t("setRBT.userSetting.selectFrndMsisdn")} </option>
                                    {friendList.map((list) => {
                                        return <option value={list.fmsisdn}>{list.friendName} ( {list.fmsisdn} ) </option>
                                    })}
                                </select>
                            </div>
                            <div className='d-flex justify-content-between my-2' >
                                <FormControlLabel value="MOBILE" id="setformobile" name="setForFrnd"
                                    control={<Radio style={{ color: 'white' }} />} label={t("setRBT.userSetting.setForMobile")}
                                    onChange={(mb) => { setSettingFor(mb.target.value) }} />
                                <input type="tel" name="mobile" id="mobile" className='text-dark p-1 border border-0 rounded'
                                    style={{ backgroundColor: 'white' }} placeholder={t("setRBT.userSetting.setForMobile")}
                                    value={fmsisdn} onChange={changefmsisdnHandler} />
                            </div>
                            <div className='d-flex justify-content-between my-2' >
                                <FormControlLabel value="DATE" name="date" control={<Radio style={{ color: 'white' }} />} label={t("setRBT.userSetting.setForDate")}
                                    onChange={(rb) => { setSettingFor(rb.target.value) }} />
                                <input
                                    type="date"
                                    onChange={changedateHandler}
                                    // ref={dateInputRef}
                                    className='text-dark p-1 border border-0 rounded'
                                    style={{ backgroundColor: 'white' }} />
                            </div>
                            <div  >
                                <FormControlLabel value="OCC" control={<Radio style={{ color: 'white' }} />}
                                    label={t("setRBT.userSetting.setForOcc")} name="occ" onChange={(occ) => { setSettingFor(occ.target.value) }} />
                                <input
                                    type="date"
                                    onChange={changedateHandler}
                                    // ref={dateInputRef}
                                    className='text-dark p-1 border border-0 rounded'
                                    style={{ marginLeft: '8.6em' }} />
                            </div>
                        </RadioGroup>
                    </div>
                </div>


                <div className='d-flex justify-content-around my-2 mx-3'>
                    <h4>
                        {t("setRBT.daysSetting.title")}
                    </h4>
                    <div className='d-flex justify-content-around flex-column'>
                        <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="Set As Default"
                                name="radio-buttons-group"
                                id="radio" >
                        <div>
                            <FormControlLabel id="daysAWeek" value=""
                                control={<Radio style={{ color: 'white' }} value="8" />} label={t("setRBT.daysSetting.daysAWeek")}
                                onChange={(dy) => { { setDays(dy.target.value) } console.log(dy.target.value) }} />
                        </div>
                        <div className='d-flex align-items-center'>
                            <FormControlLabel id="daysofWeek" value="Days of Week"
                                control={<Radio style={{ color: 'white' }} />} label={t("setRBT.daysSetting.daysofWeek.title")} />
                            <div className='d-flex justify-content-center align-items-center  fs-6' >
                                <div className='d-flex flex-column align-items-center justify-content-center'>
                                    <input type="checkbox" id="monday" onChange={daysEntry} value="1" title="Monday" />
                                    <label className='mx-2' >{t("setRBT.daysSetting.daysofWeek.mon")}</label>
                                </div>
                                <div className='d-flex flex-column align-items-center justify-content-center'>
                                    <input type="checkbox" id="tuesday" onChange={daysEntry} value="2" title="Tuesday" />
                                    <label className='mx-2' >{t("setRBT.daysSetting.daysofWeek.tue")}</label>
                                </div>
                                <div className='d-flex flex-column align-items-center justify-content-center'>
                                    <input type="checkbox" id="tuesday" onChange={daysEntry} value="3" title="Wednesday" />
                                    <label className='mx-2' >{t("setRBT.daysSetting.daysofWeek.wed")}</label>
                                </div>
                                <div className='d-flex flex-column align-items-center justify-content-center'>
                                    <input type="checkbox" id="tuesday" onChange={daysEntry} value="4" title="Thursday" />
                                    <label className='mx-2' >{t("setRBT.daysSetting.daysofWeek.thu")}</label>
                                </div>
                                <div className='d-flex flex-column align-items-center justify-content-center'>
                                    <input type="checkbox" id="tuesday" onChange={daysEntry} value="5" title="Friday" />
                                    <label className='mx-2' >{t("setRBT.daysSetting.daysofWeek.fri")}</label>
                                </div>
                                <div className='d-flex flex-column align-items-center justify-content-center'>
                                    <input type="checkbox" id="tuesday" onChange={daysEntry} value="6" title="Saturday" />
                                    <label className='mx-2' >{t("setRBT.daysSetting.daysofWeek.sat")}</label>
                                </div>
                                <div className='d-flex flex-column align-items-center justify-content-center'>
                                    <input type="checkbox" id="tuesday" onChange={daysEntry} value="7" title="Sunday" />
                                    <label className='mx-2' >{t("setRBT.daysSetting.daysofWeek.sun")}</label>
                                </div>

                            </div>
                        </div>
                       </RadioGroup>
                    </div>
                </div>



                <div className='d-flex justify-content-around my-2 '>
                    <h4>
                        {t("setRBT.timeSetting.title")}
                    </h4>
                    <div className='d-flex justify-content-around' >
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="Set As Default"
                            name="radio-buttons-group"
                            id="radio" >
                            <FormControlLabel value="25" name="fullDay" className=''
                                control={<Radio style={{ color: 'white' }}
                                    onChange={(hourfull) => {
                                        setStartHour(hourfull.target.value)
                                        setEndHour(hourfull.target.value)
                                    }} />}
                                label={t("setRBT.timeSetting.hours")} />
                            <div className='d-flex justify-content-around my-2'>
                                <div className='d-flex'>
                                    <FormControlLabel value="Set Time" className=''
                                        control={<Radio style={{ color: 'white' }} />} label={t("setRBT.timeSetting.setTime.title")} />
                                </div>
                                <div className='d-flex justify-content-around'>
                                    <div className='my-2 mx-2 '>
                                        {t("setRBT.timeSetting.setTime.startHrs")} :
                                        <input type="number" value={startHour} onChange={changestHrHandler} min={0} max={23} />
                                        {/* Start Hour : <input type="time" value={startHour} className='text-dark p-1 border border-0 rounded' onChange={changestHrHandler} /> */}
                                    </div>
                                    <div className='my-2 mx-2 '>
                                        {t("setRBT.timeSetting.setTime.endHrs")} :
                                        <input type="number" value={endHour} onChange={changeendHrHandler} min={0} max={23} />
                                        {/* <input type="time" value={endHour} className='text-dark p-1 border border-0 rounded' onChange={changeendHrHandler} /> */}
                                    </div>
                                </div>
                            </div>
                        </RadioGroup>
                    </div>
                </div>



                <div className='d-flex justify-content-around my-4 text-white'>
                    <div name='sendInfoToFmsisdn' value="0" onChange={changesendInfoToFmsisdnHandler} >
                        {t("setRBT.timeSetting.sendToFrnd")}
                        <Checkbox id='check' className='text-white' style={{ backgroundColor: 'transparent' }} value="0" />
                    </div>
                    <div>
                        <Button className='d-flex text-white border border-1 mx-5' name='sumbit' onClick={submit} >
                            {t("setRBT.submit")}
                        </Button>
                    </div>
                </div>
            </div>
        </div >

    );
}
    else {
        navigate("/")
    } 
}

export default SetRbt;
