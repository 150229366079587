import React, { Component, useEffect, useState } from 'react';
import PauseCircleOutlineSharpIcon from '@mui/icons-material/PauseCircleOutlineSharp';
import RedeemIcon from '@mui/icons-material/Redeem';
import LibraryBooksSharpIcon from '@mui/icons-material/LibraryBooksSharp';
import ShoppingCartSharpIcon from '@mui/icons-material/ShoppingCartSharp';
import PlayCircleOutlineSharpIcon from '@mui/icons-material/PlayCircleOutlineSharp';
import { Link, useLoaderData, useLocation, useNavigate } from 'react-router-dom';
import audioImage from '../images/imageServlet.png';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ReactLoading from "react-loading";
import { CloseSharp } from '@mui/icons-material';
import { Box, Button, Card, Checkbox, FormControlLabel, Modal, Radio, RadioGroup, TextField, Tooltip, Typography } from '@mui/material';
import { addRBTAlbum, addRBTIntoAlbum, giftRbt, playMusic, rbtofCat } from '../homepage/slice/RbtofCatSlice';
import { useTranslation } from 'react-i18next';
import { profileData } from '../loginAndSignup/slice/LoginSlice';




let audio = null;
function ViewRbtList() {

    const [t, i18n] = useTranslation("global");
    const location = useLocation();
    // console.log(location.state.data.catId)
    let result = sessionStorage.getItem("Username");
    result = JSON.parse(result);
    const category = location.state.data.catId;
    const username = result
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [toneRbt, settoneRbt] = useState([]);
    const [filePath, setFilePath] = useState("");
    const [playIcon, setPlayIcon] = useState(<PlayCircleOutlineSharpIcon style={{ color: "white", width: '30px', height: '30px' }} />)
    const [playButton, setPlayButton] = useState(t("sideMenu.home.play"));
    const [sendRbtCode, setSendRbtCode] = useState("");
    const [sendRBtName, setSendRBtName] = useState("");
    const data = useSelector(state => state.rbtTunes)
    const [fmsisdn, setFmsisdn] = useState("");

    // console.log(result)
    const [albumList, setAlbumList] = useState([])
    const [radioState, setRadioState] = useState("1");
    const [disablData, setDisablData] = useState(true);
    const [disablData1, setDisablData1] = useState(true);
    const [openAlbum, setOpenAlbum] = useState(false);

    const [selectAlbumName, setSelectAlbumName] = useState("");

    const [albumName, setAlbumName] = useState("");
    const handleOpenAlbum = () => setOpenAlbum(true);
    
    const handleCloseAlbum = () => {
        setOpenAlbum(false)
        setSelectAlbumName("");
        setAlbumName("");
        setDisablData(true)
        setDisablData1(true);
    }

    const [openGift, setOpenGift] = useState(false);
    const handleOpenGift = () => setOpenGift(true);
    const handleCloseGift = () => {
        setOpenGift(false)
        setFmsisdn("");
    }


    const handleEnterAlbumName = (e) => {
        albumList.map((list) => {
            if (list.albumName === albumName) {
                alert("This Album Name Is Already Exist")
            }
            else {
                setAlbumName(e.target.value)
            }
        })

    }


    const handleRadioForALbum = (e) => {
        setRadioState(e.target.value);
        if (e.target.value === "1") {
            setDisablData(false);
            setAlbumName("")
            setDisablData1(true);
        }
        else {
            setDisablData1(false);
            setSelectAlbumName("")
            setDisablData(true)
        }
    }


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'white',
        p: 2,
    };


    const handleFmsisdn = (e) => {
        if (e.target.value !== "") {
            setFmsisdn(e.target.value)
            if ((e.target.value.length < 15)) {
                setFmsisdn(e.target.value)
                console.log(process.env.REACT_APP_COUNTRY_CODE_SUDATEL)
            }
            else {
                alert("Please Enter Valid Msisdn")
            }
        }
        else {
            alert("Please Enter Valid Msisdn")
        }
    }

    const handleRbt = (rbtCodes, rbtNames) => {
        handleOpenGift()
        setSendRBtName(rbtNames)
        setSendRbtCode(rbtCodes)
    }



    const [rbtCode, setRbtCode] = useState("");
    const addRbtWithAlbum = (rbtCodes) => {
        handleOpenAlbum();
        setRbtCode(rbtCodes);
        // setAlbumName()
    }


    const loadALbumList = () => {
        dispatch(profileData({ username: username }))
            .then((resp) => {
                // console.log(resp.payload.data.crbtProfile.albumList)
                setAlbumList(resp.payload.data.crbtProfile.albumList)
            })
            .catch((error) => {
                console.error(error);
                toast.error(error);
            });
    }



    useEffect(() => {
        loadALbumList();
        dispatch(rbtofCat({ username: username, category: category }))
            .then((resp) => {
                // console.log(resp.payload.data)
                if (resp.payload.data.resMessage === "TONE LIST OF CATEGORY HAS BEEN FETCHED SUCCESSFULLY") {
                    settoneRbt(resp.payload.data.toneListOfCategory)
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error(error);
            });
    }, [])


    const addRBTtoAlbum = (rbtCode) => {
        dispatch(addRBTAlbum({ username: username, rbtCode: rbtCode }))
            .then((resp) => {
                // console.log(resp.payload.data)
                if (resp.payload.data.resMessage === "RBT ADDED SUCCESSFULLY") {
                    alert(t("setRBT.rbtAddedSuccess"))
                    navigate("/crbt/library")
                }
                else {
                    alert(resp.payload.data.resMessage)
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error(error);
            });
    }


    const giftRBTFrnd = (rbtCode) => {
        if (fmsisdn !== "") {
            dispatch(giftRbt({ username: username, rbtCode: rbtCode, fmsisdn: fmsisdn }))
                .then((resp) => {
                    // console.log(resp.payload.data)
                    if (resp.payload.data.resMessage === "RBT GIFTED SUCCESSFULLY") {
                        alert(resp.payload.data.resMessage)
                        navigate("/crbt/library")
                        handleCloseAlbum()
                    }
                    else if (resp.payload.data.resMessage === "FRIEND HAS ALREADY RBT IN HIS ALBUM") {
                        alert(resp.payload.data.resMessage)
                        navigate("/crbt/library")
                        handleCloseAlbum()
                    }
                    else {
                        alert(resp.payload.data.resMessage)
                    }
                })
                .catch((error) => {
                    // console.error(error);
                    toast.error(error);
                });
        }
        else {
            alert("Please Enter Msisdn")
        }
    }
    const playAudio = (rbt) => {
        // console.log(filePlayPath)
        audio = new Audio(rbt);
        audio.play();
    };


    const handlePlay = (playPath) => {
        // const decodedText = atob(playPath);
        // audio.crossOrigin = 'anonymous';

        if (playButton === t("sideMenu.home.pause")) {
            setPlayIcon(<PlayCircleOutlineSharpIcon style={{ color: "white", width: '30px', height: '30px' }} />)
            setPlayButton(t("sideMenu.home.play"));
        }
        if (playButton === t("sideMenu.home.play")) {
            // console.log("going to play")
            setPlayButton(t("sideMenu.home.pause"));
            setPlayIcon(<PauseCircleOutlineSharpIcon style={{ color: "white", width: '30px', height: '30px' }} />)
            dispatch(playMusic({ username: username, playPath: playPath }))
                .then((resp) => {
                    let filePlay = resp.payload.data.filePath;
                    filePlay = filePlay.replace("/home/crbtuser/voice/Crbt_Categories/", "/Crbt_Category/");
                    const rbt = require('../music' + filePlay);
                    setFilePath(rbt);
                    playAudio(rbt)
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error);
                });
        }
    }

    const handlePause = (playPath) => {
        audio.pause();
        console.log(playPath)
        if (playButton === t("sideMenu.home.pause")) {
            setPlayIcon(<PlayCircleOutlineSharpIcon style={{ color: "white", width: '30px', height: '30px' }} />)
            setPlayButton(t("sideMenu.home.play"));
        }
        if (playButton === t("sideMenu.home.play")) {
            setPlayButton(t("sideMenu.home.pause"));
            setPlayIcon(<PauseCircleOutlineSharpIcon style={{ color: "white", width: '30px', height: '30px' }} />)
        }
    }




    const addRbtIntoAlbum = () => {
        let album = "";
        if (albumName.length !== 0 && albumName !== "NA" && albumName !== "") {
            album = albumName
            dispatch(addRBTIntoAlbum({ username: username, albumName: album, rbtCode: rbtCode }))
                .then((resp) => {
                    if (resp.payload.data.resMessage === "RBT ADDED SUCCESSFULLY") {
                        alert(resp.payload.data.resMessage)
                        navigate("/crbt/library")
                        handleCloseGift()
                    }
                    else {
                        alert(resp.payload.data.resMessage)
                    }
                    // console.log(resp.payload.data.resMessage)
                })
                .catch((error) => {
                    toast.error(error);
                });

        }
        else if (selectAlbumName.length !== 0) {
            album = selectAlbumName
            dispatch(addRBTIntoAlbum({ username: username, albumName: album, rbtCode: rbtCode }))
                .then((resp) => {
                    if (resp.payload.data.resMessage === "RBT ADDED SUCCESSFULLY") {
                        alert(resp.payload.data.resMessage)
                        navigate("/crbt/library")
                        handleCloseGift()
                    }
                    else {
                        alert(resp.payload.data.resMessage)
                    }
                    // console.log(resp.payload.data.resMessage)
                })
                .catch((error) => {
                    toast.error(error);
                });

        }
        else {

            alert("Please Enter Album Name")
        }
    }










    if ((sessionStorage.getItem("Username") !== null) && (sessionStorage.getItem("Username") !== "")) {

        if (data.isLoading) {
            return (
                <div className='d-flex justify-content-center align-items-center    '>
                    <ReactLoading type="bars" color="white"
                        height={100} width={50} />
                </div>
            )
        }
        else {

            return (
                <div className='container'>
                    <div>
                        <Link to={"/crbt/categoryList"} className=" d-flex justify-content-end p-2" style={{ textDecoration: 'none' }}>
                            <Button className='text-white border border-1' style={{ backgroundColor: 'transparent' }}>{t("sideMenu.friendSetting.back")}</Button>
                        </Link>
                    </div>
                    <div className='my-4 d-flex align-items-center flex-wrap'>
                        {
                            toneRbt?.map((getrbt, i) => (
                                <div className='d-flex justify-content-between flex-column mx-2'>
                                    <div className='d-flex justify-content-center align-items-center my-2' >
                                        <Card style={{ backgroundColor: 'rgba(0, 0, 0, 0.3)' }}>
                                            <div className='p-3 d-flex justify-content-center '>
                                                <img width="60%" height="60%" src={audioImage} alt="Card image cap" />
                                            </div>
                                            <div className='d-flex justify-content-center align-items-center flex-column text-white' >
                                                <div className='d-flex text-wrap '>{getrbt.rbtName}</div>
                                                <div>{getrbt.artistName}</div>
                                            </div>
                                            <div className='d-flex justify-content-around align-items-center'>
                                                <Tooltip key={i} title={t("sideMenu.home.gift")} className='d-flex justify-content-center align-items-center text-white ' arrow>
                                                    <Button style={{ backgroundColor: 'transparent' }} className="border border-0" onClick={() => { handleRbt(getrbt.rbtCode, getrbt.rbtName) }}>
                                                        <RedeemIcon style={{ color: 'white' }} />
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip title={t("sideMenu.home.addToAlbum")} className='d-flex justify-content-center align-items-center text-white'>
                                                    <Button style={{ backgroundColor: 'transparent' }} onClick={() => { addRbtWithAlbum(getrbt.rbtCode) }} className="border border-0">
                                                        <LibraryBooksSharpIcon />
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip title={t("sideMenu.home.buy")} className='d-flex justify-content-center align-items-center text-white'>
                                                    <Button style={{ backgroundColor: 'transparent' }} onClick={() => { addRBTtoAlbum(getrbt.rbtCode) }} className="border border-0">
                                                        <ShoppingCartSharpIcon />
                                                    </Button>
                                                </Tooltip>
                                                <FormControlLabel title={playButton === t("sideMenu.home.play") ? t("sideMenu.home.play") : t("sideMenu.home.pause")} onChange={playButton === t("sideMenu.home.play") ? () => { handlePlay(getrbt.playPath, getrbt.rbtCode) } : () => { handlePause(getrbt.playPath, getrbt.rbtCode) }}
                                                    control={
                                                        <Checkbox
                                                            icon={
                                                                <Tooltip title={t("sideMenu.home.play")} className='d-flex justify-content-center align-items-center text-white' arrow>
                                                                    <PlayCircleOutlineSharpIcon style={{ color: "white", width: '30px', height: '30px' }} />
                                                                </Tooltip>
                                                            }
                                                            checkedIcon={
                                                                <Tooltip title={t("sideMenu.home.pause")} className='d-flex justify-content-center align-items-center text-white' arrow>
                                                                    <PauseCircleOutlineSharpIcon style={{ color: "white", width: '30px', height: '30px' }} />
                                                                </Tooltip>
                                                            }
                                                            name="checkedH" />
                                                    }
                                                />
                                            </div>
                                        </Card>
                                    </div>
                                </div>
                            ))
                        }
                        <div>
                            <Modal
                                 open={openGift}
                                 onClose={handleCloseGift}
                                style={{ backgroundColor: 'transparent' }}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={style} className='rounded'>
                                    <div className='d-flex justify-content-center align-items-center flex-column'>
                                        <div className='d-flex justify-content-around align-items-center w-100'>
                                            <Typography id="modal-modal-title" >
                                                <h5><b>{t("sideMenu.home.giftRBT")}</b></h5>
                                            </Typography>
                                            <div className='d-flex justify-content-end position-absolute top-0 end-0' >
                                                <Button className='text-danger border border-1 border-danger ' onClick={handleCloseGift}>
                                                    <CloseSharp />
                                                </Button>
                                            </div>
                                        </div>
                                        <Typography id="modal-modal-description" className='d-flex mt-4'>
                                            You are gifting this <b>"{sendRBtName}"</b> to your friend
                                        </Typography>
                                        <div className=" d-flex justify-content-between align-items-center my-3">
                                            <Typography className='d-flex mx-2' id="modal-modal-description">
                                                <TextField id="outlined-basic" label="Enter Friend MSISDN" variant="outlined" value={fmsisdn} onChange={handleFmsisdn} />
                                            </Typography>
                                            <div className='d-flex'>
                                                <Button style={{ backgroundColor: 'transparent' }} className=" d-flex justify-content-center text-dark border border-black border-1" onClick={() => { giftRBTFrnd(sendRbtCode) }}>{t("setRBT.submit")}</Button>
                                            </div>

                                        </div>
                                    </div>
                                </Box>
                            </Modal>
                        </div>
                         {/* PopUp for Album */}
                         <div>
                            <Modal
                                open={openAlbum}
                                onClose={handleCloseAlbum}
                                // className='bg-transparent'
                                style={{ backgroundColor: 'transparent' }}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                {/* <div className='d-flex justify-content-center align-items-center vh-100' style={{backgroundColor:'transparent'}}> */}
                                <Box sx={style} className='rounded'>
                                    <div className='d-flex justify-content-center align-items-center flex-column'>
                                        <div className='d-flex justify-content-center align-items-center w-100 my-2'>
                                            <Typography id="modal-modal-title"  >
                                                <h6><b>Select The Album Or Create a New Album</b></h6>
                                            </Typography>
                                            <div className='d-flex justify-content-end position-absolute top-0 end-0' >
                                                <Button className='text-danger border border-1 border-danger ' onClick={handleCloseAlbum}>
                                                    <CloseSharp />
                                                </Button>
                                            </div>
                                        </div>
                                        <div className="w-75 ">
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                defaultValue="Set As Default"
                                                name="radio-buttons-group"
                                                id="radio"
                                                onChange={handleRadioForALbum} >
                                                <div className='d-flex justify-content-start align-items center my-2 '>
                                                    <FormControlLabel value="1" control={<Radio />}
                                                        label="Select Album " style={{ fontWeight: '8px' }} />
                                                    <Typography className='d-flex border border-dark rounded ' id="modal-modal-description">
                                                        <select value={selectAlbumName} onChange={(e) => { setSelectAlbumName(e.target.value) }} disabled={disablData} style={{ width: '195px' }} >
                                                            {
                                                                albumList.map((list) => (
                                                                    <option value={list.albumName}>{list.albumName}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </Typography>
                                                </div>
                                                <div className='d-flex justify-content-start align-items center my-2'>
                                                    <FormControlLabel value="2" control={<Radio />}
                                                        label="Enter Album " />
                                                    <Typography>
                                                        <input type="text" name="mobile" className='p-1 border border-dark rounded' placeholder="Enter Album Name" value={albumName} onChange={(e) => {
                                                            setAlbumName(e.target.value)
                                                        }} disabled={disablData1} />
                                                        {/* <TextField id="outlined-basic" label="Enter Album Name" variant="outlined" value={albumName} onChange={handleAlbumName} /> */}
                                                    </Typography>
                                                </div>
                                            </RadioGroup>
                                            <div className='d-flex justify-content-end '>
                                                <Button style={{ backgroundColor: 'transparent' }} className=" d-flex justify-content-end text-dark border border-black border-1" onClick={() => {
                                                    if (selectAlbumName !== "") {
                                                        addRbtIntoAlbum(selectAlbumName, rbtCode)
                                                    } else { addRbtIntoAlbum(albumName, rbtCode) }
                                                }}>{t("setRBT.submit")}</Button>
                                            </div>
                                        </div>
                                    </div>
                                </Box>
                                {/* </div> */}
                            </Modal>
                        </div>

                    </div >
                </div>
            );
        }
    }
    else {
        navigate("/")
    }
}

export default ViewRbtList;
